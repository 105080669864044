import React from 'react'
import './TotalNav.sass'
import doc1 from './doctor1.png'
import doc2 from './doctor2.png'
import InfoLine from '../../ui/InfoLine/InfoLine'
import Button from '../../ui/Button/Button'

function TotalNav() {
  const buttonsContent = {}

  return (
    <section class="MainButtons">
      <div class="MainButtons-Container DefaultContainer">
        <div class="MainButton" style={{ backgroundImage: `url(${doc1})` }}>
          <div class="TextContainer">
            <h2>Запись на медкомиссию</h2>
            <div class="MainButton-BenefitsList">
              <InfoLine
                icon="check"
                title="Все виды медицинских комиссий в Мурманске"
                className="MainButton-Benefit"
              />
              <InfoLine
                icon="check"
                title="Запись осуществляется в удобное для Вас время"
                className="MainButton-Benefit"
              />
              <InfoLine
                icon="check"
                title="Без очередей"
                className="MainButton-Benefit"
              />
            </div>
            <Button
              theme="primary-white"
              title="Записаться"
              type="navlink"
              icon="arrow-right"
              iconPosition="right"
              path="/zapis-na-medkomissiyu"
            />
          </div>
        </div>

        <div
          class="MainButton MainButton_theme_light"
          style={{ backgroundImage: `url(${doc2})` }}
        >
          <div class="TextContainer">
            <h2>Онлайн запись на прием к врачу</h2>
            <div class="MainButton-BenefitsList">
              <InfoLine
                icon="check"
                title="Высококвалифицированный персонал"
                className="MainButton-Benefit"
              />
              <InfoLine
                icon="check"
                title="Запись осуществляется в удобное для Вас время"
                className="MainButton-Benefit"
              />
              <InfoLine
                icon="check"
                title="Без очередей"
                className="MainButton-Benefit"
              />
            </div>
            <Button
              theme="primary-white"
              title="Записаться"
              type="navhashlink"
              icon="arrow-right"
              iconPosition="right"
              path="/zapis-na-priem"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TotalNav
