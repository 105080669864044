import React from 'react'
import { Item, Span } from 'react-html-email'
import EmailBody from './EmailBody/EmailBody'

function SignUpEmail({ login = 'test', password = 'test' }) {
  return (
    <EmailBody>
      <Item align="center">
        <Span
          style={{
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          Регистрация успешно завершена!
        </Span>
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      >
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Благодарим Вас за регистрацию на сайте АСДМС. Ваш логин и пароль для
          входа в личный кабинет:
        </Span>
      </Item>
      <Item
        align="center"
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          paddingTop: '24px',
          paddingBottom: '24px',
          backgroundColor: '#F1F7F9',
          borderRadius: '8px',
        }}
      >
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Логин: <b>{login}</b>
        </Span>
        <br />
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Пароль: <b>{password}</b>
        </Span>
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      >
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Если Вы хотите установить новый пароль, воспользуйтесь функцией
          восстановления пароля на сайте.
        </Span>
      </Item>
    </EmailBody>
  )
}

export default SignUpEmail
