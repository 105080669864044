import React, { useContext } from 'react'
import Radio from '../../../../ui/Field/Radio/Radio'
import './Clinic.sass'
import { AuthContext } from '../../../..'
import { Map, Placemark, YMaps } from 'react-yandex-maps'
import updateDoc from '../../../../utils/db/updateDoc'
import CheckoutButtons from '../CheckoutButtons/CheckoutButtons'
import SectionTitle from '../../../../ui/SectionTitle/SectionTitle'

function Clinic() {
  const { cities, cart } = useContext(AuthContext)

  const setClinic = (id) => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { clinicId: id },
    }).then(() => {})
  }

  const onNextStep = () => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { curStep: 'specialization', specialization: [], products: [] },
    }).then(() => {})
  }

  return (
    <div className="Clinic">
      {cities.map((c) => (
        <ClinicTile
          key={c.cityId}
          {...c}
          cartClinicId={cart.clinicId}
          onClick={setClinic}
        />
      ))}
      <CheckoutButtons
        onNextStep={onNextStep}
        isActiveNextStep={cart.clinicId && true}
      />
    </div>
  )
}

function ClinicTile({ cityId, title, contacts, cartClinicId = null, onClick }) {
  const bHours = contacts.busyHours.split(',')

  return (
    <div className="ClinicTile" onClick={() => onClick(cityId)}>
      <div className="Address-Container">
        <Radio value={cityId === cartClinicId ? 'on' : null} />
        <div className="Address">
          <h4 className="Title">г. {title}</h4>
          <div>
            <p className="Body-Bold-2">Адрес:</p>
            <p className="Caption-Regular">{contacts.address}</p>
          </div>
          <div>
            <p className="Body-Bold-2">Контакты:</p>
            <p className="Caption-Regular">{contacts.phone}</p>
          </div>
          <div>
            <p className="Body-Bold-2">Режим работы:</p>
            <p className="Caption-Regular">
              {bHours[0]}
              <br />
              {bHours[1]}
            </p>
          </div>
        </div>
      </div>
      <YMaps>
        <Map
          className="Map"
          defaultState={{
            center: contacts.coords,
            zoom: 16,
          }}
        >
          <Placemark
            geometry={contacts.coords}
            options={{ preset: 'islands#blueIcon' }}
          />
        </Map>
      </YMaps>
    </div>
  )
}

export default Clinic
