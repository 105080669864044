import React, { useState } from 'react'
import './CheckoutSpecialization.sass'
import Icon from '../../../../ui/Icon/Icon'
import { useEffect } from 'react'
import getCollection from '../../../../utils/db/getCollection'
import { cloneDeep, find, isEmpty, isNull, orderBy } from 'lodash'
import { useContext } from 'react'
import { AuthContext } from '../../../..'
import { NavLink } from 'react-router-dom'
import CheckoutButtons from '../CheckoutButtons/CheckoutButtons'
import updateDoc from '../../../../utils/db/updateDoc'
import Spinner from '../../../../ui/Spinner/Spinner'

function CheckoutSpecialization() {
  const [services, setServices] = useState(null)
  const { cart, cities } = useContext(AuthContext)
  const cartCity = find(cities, ['cityId', cart.clinicId])

  const [choosedSpecializations, setChoosedSpecializations] = useState(
    cart.specialization ? cart.specialization : []
  )

  useEffect(() => {
    if (cartCity) {
      getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
      }).then((result) => {
        setServices(
          orderBy(
            result.filter(
              (s) => s.cityId === cartCity.cityId && s.isDeleted !== true
            ),
            ['pos']
          )
        )
      })
    }
  }, [cartCity, cart])

  const onPrevStep = () => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { curStep: 'clinic', products: [], specialization: [] },
    }).then(() => {})
  }

  const onNextStep = () => {
    let curStep = 'services'

    const filteredSpec = services.filter((s) =>
      choosedSpecializations.includes(s.categoryId)
    )
    if (filteredSpec.every((s) => s.fixedCheckout && true)) {
      curStep = 'doctor'
    }

    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { curStep: curStep, specialization: choosedSpecializations },
    })
  }

  const onSpecializationClicked = (id) => {
    let clone = cloneDeep(choosedSpecializations)
    if (clone.includes(id)) {
      clone = clone.filter((c) => c !== id)
    } else {
      clone.push(id)
    }
    setChoosedSpecializations(clone)
  }

  return (
    <div className="CheckoutSpecialization">
      {!isNull(services) ? (
        <>
          <p className="CheckoutSpecialization-Title">
            Услуги, требующие уточнения стоимости у специалиста
          </p>
          {services
            .filter((s) => s.fixedCheckout)
            .map((s) => (
              <SpecializationTile
                isActive={choosedSpecializations.includes(s.categoryId)}
                {...s}
                onClick={onSpecializationClicked}
              />
            ))}
          <p className="CheckoutSpecialization-Title">Услуги и цены</p>
          {services
            .filter((s) => !s.fixedCheckout)
            .map((s) => (
              <SpecializationTile
                isActive={choosedSpecializations.includes(s.categoryId)}
                {...s}
                onClick={onSpecializationClicked}
              />
            ))}
        </>
      ) : (
        <Spinner type="module" />
      )}

      <CheckoutButtons
        onPrevStep={onPrevStep}
        onNextStep={onNextStep}
        isActiveNextStep={!isEmpty(choosedSpecializations) && true}
      />
    </div>
  )
}

function SpecializationTile({
  isActive,
  title = 'title',
  icon = {},
  categoryId,
  onClick,
}) {
  return (
    <div onClick={() => onClick(categoryId)}>
      <div
        id={categoryId}
        className={`SpecializationTile ${
          isActive && 'SpecializationTile_theme_active'
        }`}
      >
        <div className="Icon-Container">
          <img src={icon.publicUrl} alt={title} />
        </div>
        <p className="Body-Bold-2">{title}</p>
      </div>
    </div>
  )
}

export default CheckoutSpecialization
