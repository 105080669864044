import React, { useState } from 'react'
import './DeletePaymentCardPopUp.sass'
import PopUp from '../../../../ui/PopUp/PopUp'
import Button from '../../../../ui/Button/Button'
import { AuthContext } from '../../../../App'

function DeletePaymentCardPopUp({ number, close, onPaymentCardDelete }) {
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    onPaymentCardDelete(number)
  }
  return (
    <PopUp
      show
      title={'Видалення карти'}
      className={`DeletePaymentCardPopUp`}
      icon="trash"
      close={close}
    >
      <p className="Text Body-Regular-2">
        Ви справді бажаєте видалити банківську карту{' '}
        <span className="Body-Bold-2">
          •••• {number.slice(number.length - 4)}
        </span>
      </p>
      <div className="Buttons">
        <Button
          title="Видалити карту"
          size={40}
          onClick={onDelete}
          isLoading={isLoading}
        />
        <Button
          title="Відмінити"
          size={40}
          theme="internal-link"
          onClick={close}
        />
      </div>
    </PopUp>
  )
}

export default DeletePaymentCardPopUp
