import { find, isEmpty, orderBy } from 'lodash'
import React, { useEffect } from 'react'
import { useState } from 'react'
import DoctorTile from '../../pages/Doctors/components/DoctorTile/DoctorTile'
import Button from '../../ui/Button/Button'
import Carousel from '../../ui/old/Carousel/Carousel'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import getCollection from '../../utils/db/getCollection'
import './DoctorsSlider.sass'

function DoctorsSlider() {
  const [doctors, setDoctors] = useState(null)

  useEffect(() => {
    getCollection({ path: 'doctors', docIdName: 'doctorId' }).then((data) =>
      setDoctors(
        orderBy(data, ['pos', 'asc']).filter((d) => d.isDeleted === false)
      )
    )
  }, [])

  return (
    <section className="DoctorsSlider">
      <div className="DefaultContainer">
        <SectionTitle title="Наши специалисты" />
        <div className="DoctorsSlider-Container">
          <div className="InfoBlock">
            <p>
              Многолетний опыт работы делает наших врачей{' '}
              <b>высококвалифицированными специалистами.</b>
            </p>
            <Button
              theme="primary-white"
              title="Все специалисты"
              type="navhashlink"
              icon="arrow-right"
              iconPosition="right"
              path="/vrachi"
            />
          </div>
          {!isEmpty(doctors) && (
            <div className="DoctorsCarousel">
              <Carousel
                options={{
                  type: 'carousel',
                  gap: 24,
                  perView: 4,
                  // peek: {
                  //   before: 128,
                  //   after: 128,
                  // },
                  autoplay: 5000,
                  useDots: true,
                  useArrows: true,
                  breakpoints: {
                    576: {
                      perView: 2,
                    },
                  },
                }}
              >
                {doctors.map((d) => {
                  const avatar = find(d.photos, ['isAvatar', true])
                  const stage = new Date().getFullYear() - d.startingYear
                  return (
                    <DoctorTile
                      key={d.doctorId}
                      {...d}
                      stage={stage}
                      avatar={avatar ? avatar.publicUrl : null}
                    />
                  )
                })}
              </Carousel>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default DoctorsSlider
