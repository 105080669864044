import React from 'react'
import './CookieNotification.sass'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import { useContext } from 'react'
import { AuthContext } from '../..'
import { find } from 'lodash'

function CookieNotification() {
  const { cities } = useContext(AuthContext)
  const defaultCity = find(cities, ['isDefault', true])

  return (
    <CookieConsent
      location="bottom"
      containerClasses="CookieNotification"
      buttonClasses="CookieNotification-Button"
      buttonText="Принять"
      cookieName="CookieNotification"
    >
      <Icon name="cookie-bite" />
      Продолжая пользование настоящим сайтом Вы выражаете своё согласие на
      обработку Ваших персональных данных (файлов cookie) с использованием
      трекеров "Google Analytics", "Yandex.Metrics". Порядок обработки Ваших
      персональных данных, а также реализуемые требования к их защите,
      содержатся в{' '}
      <Link to={`/docs/${defaultCity.cityId}/policy.pdf`} target="blank">
        Политике обработки персональных данных ООО "АСД".
      </Link>
    </CookieConsent>
  )
}

export default CookieNotification
