import { isEmpty } from 'lodash'
import getCollection from '../../../../utils/db/getCollection'
import updateDoc from '../../../../utils/db/updateDoc'
import { firebase } from '../../../../config/firebase'

const resetPasswordByRecoveryCode = ({ recoveryCode, newPassword }) => {
  return getCollection({
    path: 'users',
    docIdName: 'userId',
    whereQueries: [
      { fieldPath: 'passwordRecoveryCode', op: '==', value: recoveryCode },
    ],
  }).then((result) => {
    if (!isEmpty(result)) {
      const user = result[0]

      return updateDoc({
        path: 'users',
        docId: user.userId,
        data: { passwordRecoveryLock: true, password: newPassword },
      }).then(() =>
        firebase
          .auth()
          .signInWithEmailAndPassword(user.email, user.password)
          .then(() => firebase.auth().currentUser.updatePassword(newPassword))
      )
    }
  })
}

export default resetPasswordByRecoveryCode
