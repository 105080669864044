import React from 'react'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import l from './logo.svg'
import './Logo.sass'

function Logo() {
  return (
    <NavHashLink
      to={'/#home'}
      smooth
      scroll={(el) => scrollWithOffset(el, -120)}
    >
      <div className="Logo">
        <img src={l} alt="АСД МЦ" />
      </div>
    </NavHashLink>
  )
}

export default Logo
