import React from 'react'
import './PasswordResetField.sass'
import Button from '../../../../ui/Button/Button'
import Icon from '../../../../ui/Icon/Icon'
import Media from 'react-media'

function PasswordResetField({ onPasswordReset }) {
  return (
    <div className="PasswordReset">
      <div className="PasswordReset-F">
        <h4>Пароль</h4>
        <p className="Content">
          <Icon name="lock-alt" />
          <span>••••••••••••</span>
        </p>
      </div>
      <Media
        query="(min-width: 1366px)"
        render={() => (
          <Button
            title="Изменить пароль"
            icon="edit"
            iconPosition="left"
            size={32}
            theme="secondary"
            onClick={onPasswordReset}
          />
        )}
      />
      <Media
        query="(max-width: 1366px)"
        render={() => (
          <Icon className="EditIcon" name="edit" onClick={onPasswordReset} />
        )}
      />
    </div>
  )
}

export default PasswordResetField
