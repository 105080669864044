import React, { useContext, useState } from 'react'
import PopUp from '../../../../../ui/PopUp/PopUp'
import Button from '../../../../../ui/Button/Button'
import './CancelEventPopUp.sass'
import updateDoc from '../../../../../utils/db/updateDoc'
import getDoc from '../../../../../utils/db/getDoc'
import { AuthContext } from '../../../../..'

function CancelEventPopUp({ close, eventId, eventType }) {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext(AuthContext)

  const onDelete = () => {
    setIsLoading(true)
    if (eventType === 'individual') {
      updateDoc({
        path: 'schedule',
        docId: eventId,
        data: {
          status: 'empty',
          user: null,
          payment: null,
        },
      }).then(() => close())
    } else {
      getDoc({ path: 'schedule', docId: eventId })
        .then((result) => {
          const members = result.members.filter((m) => m.userId !== user.uid)
          console.log(
            '🚀 ~ file: CancelEventPopUp.jsx:29 ~ .then ~ members:',
            members
          )

          return updateDoc({
            path: 'schedule',
            docId: eventId,
            data: {
              members,
            },
          })
        })
        .then(() => close())
    }
  }

  return (
    <PopUp
      show
      title={'Отменить запись'}
      className={`CancelEventPopUp`}
      close={close}
    >
      <p className="Text Body-Regular-2">
        Вы уверены, что хотите отменить запись на прием?
        {/* <span className="Body-Bold-2">
          •••• {number.slice(number.length - 4)}
        </span> */}
      </p>
      <div className="Buttons">
        <Button
          title="Отменить запись"
          size={40}
          onClick={onDelete}
          isLoading={isLoading}
        />
        <Button
          title="Закрыть"
          size={40}
          theme="internal-link"
          onClick={close}
        />
      </div>
    </PopUp>
  )
}

export default CancelEventPopUp
