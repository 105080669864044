import React from 'react'
import './PaymentCardPopUp.sass'
import Button from '../../../../ui/Button/Button'
import FondyAttachCardForm from '../../../../components/FondyPaymentForm/FondyAttachCardForm'
import useScrollLock from '../../../../hooks/useScrollLock'

function PaymentCardPopUp({
  onPaymentCardAddSuccess,
  onPaymentCardAddFailed,
  close,
}) {
  useScrollLock()

  return (
    <div className="PaymentCardPopUp">
      <div className="BackButton">
        <Button
          title="Повернутися"
          icon="angle-left"
          theme="internal-link"
          size={32}
          onClick={close}
        />
      </div>
      <div className="CardForm-Container">
        <FondyAttachCardForm
          onPaymentSuccess={onPaymentCardAddSuccess}
          onPaymentCardAddFailed={onPaymentCardAddFailed}
        />
      </div>
    </div>
  )
}

export default PaymentCardPopUp
