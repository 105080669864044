import sha1 from 'js-sha1'
import merchantConfig from '../../config/fondy'

const generateSignature = (request) => {
  const stringToCode = Object.keys(request)
    .sort()
    .map((k) => request[k])

  stringToCode.unshift(merchantConfig.secretKey)
  return sha1(stringToCode.join('|'))
}

export default generateSignature
