import { find } from 'lodash'
import React from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../index'
import Button from '../../ui/Button/Button'
import Icon from '../../ui/Icon/Icon'
import './Footer.sass'
import logoWhite from './logoWhite.svg'

function Footer() {
  const { cities } = useContext(AuthContext)
  const defaultCity = find(cities, ['isDefault', true])
  console.log('🚀 ~ file: Footer.jsx:12 ~ Footer ~ city:', defaultCity)
  return (
    <footer id="contacts" class="Footer">
      <div class="Footer-Container DefaultContainer">
        <a href="/" class="Logo">
          <img src={logoWhite} alt="МЦ Будь Здоров" />
        </a>
        <div class="FooterBlock">
          <div class="FooterIcon">
            <Icon name="file-alt" />
          </div>
          <div class="FooterLinks">
            <span className="Title">Правовые документы </span>
            <p style={{ display: 'inline-flex' }}>
              <a
                href={`/docs/${defaultCity.cityId}/license.pdf`}
                target="blank"
              >
                Лицензия,
              </a>
              &nbsp;
              <a href={`/docs/${defaultCity.cityId}/ustav.pdf`} target="blank">
                Устав,
              </a>
              &nbsp;
              <a href={`/docs/${defaultCity.cityId}/inn.pdf`} target="blank">
                ИНН,
              </a>
              &nbsp;
              <a href={`/docs/${defaultCity.cityId}/ogrn.pdf`} target="blank">
                ОГРН
              </a>
            </p>
            <a href={`/docs/${defaultCity.cityId}/reestr.pdf`}>
              Выписка из реестра
            </a>
            <a href={`/docs/${defaultCity.cityId}/prikaz.pdf`}>
              Приказ и внутренний распорядок для потребителей медицинских услуг
            </a>
            <a href={`/docs/rebenok.pdf`}>
              Ребенок на приеме у медицинского сотрудника
            </a>
            <a href={`/docs/${defaultCity.cityId}/policy.pdf`}>
              Политика защиты и обработки персональных данных
            </a>
          </div>
        </div>
        <div class="FooterBlock">
          <div class="FooterIcon">
            <Icon name="notes-medical" />
          </div>
          <div class="FooterLinks">
            <span className="Title">МЦ "АСДМС" </span>
            <a href="/#home">Главная</a>
            <a href="/uslugi-i-ceny">Услуги и цены</a>
            <a href="/napravleniya">Направления</a>
            <a href="/vrachi">Врачи</a>
            <a href="/zapis-na-priem">Онлайн запись</a>
            <a href="/#about">Наш центр</a>
          </div>
        </div>
        {/* <div class="HiddenBlock"></div> */}
        <div class="FooterBlock CtBlock">
          <div class="FooterIcon">
            <Icon name="headset" />
          </div>
          <div class="FooterLinks">
            <span className="Title">Наши контакты</span>
            <p>{defaultCity.contacts.address}</p>
            <p>Режим работы: {defaultCity.contacts.busyHours}</p>
            <p>
              Тел. для физ. лиц:{' '}
              <a href={`tel:${defaultCity.contacts.phone}`}>
                {defaultCity.contacts.phone}
              </a>
            </p>

            <p>
              Тел. для юр. лиц:{' '}
              <a href={`tel:+78152202121`}>+7 (8152) 20-21-21</a>
            </p>
            <p>
              E-mail: <a href={`mailto:asdnord@yandex.ru`}>asdnord@yandex.ru</a>
            </p>
            <p>
              Мы в соц. сетях:{' '}
              <a href={defaultCity.contacts.social.vk} target="blank">
                <Icon className="SocIcon" name="vk" weight="brand" />
              </a>
            </p>
            {/* <div class="LinkText">
              г. Мурманск ул. Свердлова 2, к3
              <br />
              Тел: <a href="tel: +7 (8152) 58-44-58">+7 (8152) 58-44-58</a>
              Тел. для юр. лиц:
              <br /> <a href="tel: +7 (8152) 20-21-21">+7 (8152) 20-21-21</a>
            </div> */}
            <Button
              theme="primary-white"
              title="Записаться на прием"
              type="navhashlink"
              icon="arrow-right"
              iconPosition="right"
              path="/zapis-na-priem"
              className="FooterCheckoutBut"
            />
          </div>
        </div>
      </div>
    </footer>
  )
}

function FooterLinkBlock({ title, icon, content }) {
  return (
    <div className="Footer-LinkBlock">
      <p className="Title">
        <Icon name={icon} weight="solid" />
        <h3>{title}</h3>
      </p>
      <div className="Content Body-Regular-2">{content}</div>
    </div>
  )
}

export default Footer
