import React, { useContext } from 'react'
import PopUp from '../../ui/PopUp/PopUp'
import ContactUsForm from './ContactUsForm/ContactUsForm'
import './ContactUsPopUp.sass'
import { AuthContext } from '../../index'

function ContactUsPopUp() {
  const { showContactUs, setShowContactUs } = useContext(AuthContext)
  return (
    <PopUp
      show={showContactUs.show}
      close={() =>
        setShowContactUs({ show: false, title: '', emailSubject: '' })
      }
      title={showContactUs.title}
      className="ContactUsPopUp"
    >
      <ContactUsForm
        subject={showContactUs.emailSubject}
        additionalContent={showContactUs.additionalContent}
      />
    </PopUp>
  )
}

export default ContactUsPopUp
