import React, { useEffect, useState } from 'react'
import { createForm } from '../../../utils/forms/createForm'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import './PasswordRecovery.sass'
import Button from '../../../ui/Button/Button'
import FieldRender from '../../../utils/forms/render/FieldRender'
import getFormValues from '../../../utils/forms/getFormValues'
import isFormValid from '../../../utils/forms/validation/isFormValid'
import sendRecoveryLink from './functions/sendRecoveryLink'
import { withRouter } from 'react-router'

function PasswordRecovery({ onMessageSend, ...router }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        recovery: {
          field: {
            fieldId: 'recovery',
            fieldType: 'input',
            inputType: 'email',
            validationPattern: 'email',
            label: 'Е-mail',
            placeholder: 'sample@gmail.com',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )

  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   if (
  //     form.recovery.value[0] === '+' &&
  //     (form.recovery.config.type === 'text' ||
  //       form.recovery.config.type === 'email')
  //   ) {
  //     setForm(
  //       createForm({
  //         formPattern: {
  //           recovery: {
  //             field: {
  //               fieldId: 'recovery',
  //               fieldType: 'input',
  //               inputType: 'phone',
  //               label: 'E-mail или телефон',
  //               placeholder: 'sample@gmail.com или +7 (XXX)-XXX-XX-XX',
  //               mask: 'phone',
  //               required: true,
  //               value: form.recovery.value,
  //             },
  //             render: getFieldRenderObject(),
  //           },
  //         },
  //       })
  //     )
  //   } else if (
  //     form.recovery.value === '+7 (' &&
  //     form.recovery.config.type === 'phone'
  //   ) {
  //     setForm(
  //       createForm({
  //         formPattern: {
  //           recovery: {
  //             field: {
  //               fieldId: 'recovery',
  //               fieldType: 'input',
  //               inputType: 'email',
  //               label: 'E-mail или телефон',
  //               placeholder: 'sample@gmail.com или +7 (XXX)-XXX-XX-XX',
  //               validationPattern: 'email',
  //               required: true,
  //               value: '',
  //             },
  //             render: getFieldRenderObject(),
  //           },
  //         },
  //       })
  //     )
  //   }
  // }, [form])

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid({ form })) {
      return setShowErrors(true)
    } else {
      setShowErrors(false)
      setIsLoading(true)
      sendRecoveryLink({
        to: form.recovery.value,
        passwordRecoveryPage: router.location.pathname,
      })
        .then(() => onMessageSend(form.recovery.value))
        .catch((e) => {
          console.log(
            '🚀 ~ file: PasswordRecovery.jsx ~ line 95 ~ onSubmit ~ e',
            e
          )
        })
    }
  }

  return (
    <form className="PasswordRecovery-Form" onSubmit={onSubmit}>
      <p className="Text Body-Regular-2">
        Для восстановления пароля введите E-mail
      </p>
      <FieldRender
        key={'recovery'}
        field={{ ...form['recovery'], fieldId: 'recovery' }}
        form={form}
        setForm={setForm}
        showErrors={showErrors}
      />
      <Button
        title="Восстановить"
        theme="primary"
        size={40}
        isLoading={isLoading}
        onClick={onSubmit}
        iconPosition="right"
        type="submit"
      />
    </form>
  )
}

export default withRouter(PasswordRecovery)
