import React from 'react'
import Icon from '../../../../ui/Icon/Icon'
import InfoLine from '../../../../ui/InfoLine/InfoLine'
import './DoctorTile.sass'

function DoctorTile({ avatar, title, stage, description }) {
  return (
    <div className="DoctorTile">
      <div className="Doctor-Avatar">
        {avatar ? <img src={avatar} alt={title} /> : <Icon name="user-md" />}
      </div>
      <div>
        <p className="Body-Bold-1 Title">{title}</p>
        <p className="Stage Body-Medium-2">
          Стаж:{' '}
          <span className="StageCounter">
            {stage} {trueRussianDecline('лет', 'год', 'года', stage)}
          </span>
        </p>
      </div>

      <p className="Description Caption-Regular">{description}</p>
    </div>
  )
}

export function trueRussianDecline(d1, d2, d3, c) {
  let res = ''
  switch (c % 100) {
    case 11:
    case 12:
    case 13:
    case 14:
      return d1
    default:
      switch (c % 10) {
        case 0:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return d1
        case 1:
          return d2
        case 2:
        case 3:
        case 4:
          return d3
      }
  }
  return ''
}

export default DoctorTile
