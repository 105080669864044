import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../index'
import Button from '../../ui/Button/Button'
import CityChooser from '../CityChooser/CityChooser'
import ContactUsPopUp from '../ContactUs/ContactUsPopUp'
import './Top.sass'
import { find } from 'lodash'

function Top() {
  const { cities, showContactUs } = useContext(AuthContext)
  const defaultCity = find(cities, ['isDefault', true])

  return (
    <div className="Top">
      {showContactUs.show && <ContactUsPopUp />}
      <div className="Top-Container DefaultContainer">
        <CityChooser />
        <div className="Top-Contact-Buttons">
          <Button
            theme="internal-link"
            type="link"
            path={`mailto: ${defaultCity.offices[0].contacts.email}`}
            size={40}
            icon="envelope"
            iconWeight="solid"
            title={defaultCity.offices[0].contacts.email}
            className="Top-ContactsEmail"
          />
          <Button
            theme="internal-link"
            type="link"
            path={`tel: ${defaultCity.offices[0].contacts.phone}`}
            size={40}
            icon="phone"
            iconWeight="solid"
            title={defaultCity.offices[0].contacts.phone}
          />
        </div>
      </div>
    </div>
  )
}

export default Top
