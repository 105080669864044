import React, { useRef } from 'react'
import './SceneTable.sass'
import isObject from 'lodash/isObject'
import find from 'lodash/find'
import { usePopUp } from '../../../hooks/usePopUp'
import OptionsMenu from '../../OptionsMenu/OptionsMenu'
import OptionsButton from './OptionsButton'

function SceneTable({ header, body, options, editOnDoubleClick }) {
  return (
    <table className="SceneTable">
      <thead>
        <tr>
          {header.map(([col, width], index) => (
            <th
              key={`${col}-${index}`}
              style={width && { width: `${width}px` }}
            >
              {col}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{renderBody(body, options, editOnDoubleClick)}</tbody>
    </table>
  )
}

const renderBody = (body, options, editOnDoubleClick) => {
  const renderedBody = body.map((row, ind) => (
    <RenderRow
      row={row}
      pos={ind}
      options={options}
      editOnDoubleClick={editOnDoubleClick}
    />
  ))

  return renderedBody.length ? (
    renderedBody
  ) : (
    <tr className="NoInfoRow Caption-Regular">
      <td colSpan="100">Немає інформації</td>
    </tr>
  )
}

function RenderRow({ row, pos, options, editOnDoubleClick }) {
  const [{ id: elementId }] = row.filter((element) => isObject(element) && true)
  const editOption = find(options, ['type', 'edit'])
  const optButRef = useRef()
  const ddBRef = useRef()

  const [showPopUp] = usePopUp(optButRef, ddBRef)

  return (
    <tr
      onDoubleClick={editOnDoubleClick && (() => editOption.clicked(elementId))}
    >
      {row
        .filter((element) => !element.hasOwnProperty('id') && true)
        .map((element) => {
          if (element !== 'options') {
            return <td>{element}</td>
          }
          return (
            <td className="OptionsButton-Container">
              <OptionsButton ref={optButRef} />
              {showPopUp && (
                <OptionsMenu
                  ref={ddBRef}
                  styles={{ minWidth: '120px' }}
                  options={options.map((option) => ({
                    ...option,
                    clicked: () =>
                      option.clicked(
                        option.return === 'docId' ? elementId : pos
                      ),
                  }))}
                />
              )}
            </td>
          )
        })}
    </tr>
  )
}

export default SceneTable
