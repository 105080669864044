import React from 'react'
import InfoLine from '../../ui/InfoLine/InfoLine'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './About.sass'
import aboutImg from './about.jpg'
import Button from '../../ui/Button/Button'
import ReactPlayer from 'react-player'
import light from './light.png'

function About() {
  return (
    <section id="ocentre" name="about" class="About">
      <div className="DefaultContainer">
        <SectionTitle title={`Медицинский центр \"АСДМС\"`} />
        <div class="About-Container">
          <div className="AboutVideo">
            <ReactPlayer
              url={'/video.mp4'}
              width="100%"
              height="100%"
              controls
              light={light}
              // light={
              //   find(data.photos, ['isTeaser', true]) &&
              //   find(data.photos, ['isTeaser', true]).publicUrl
              // }
            />
          </div>

          <div class="About-Text">
            <p>
              Медицинский центр АСДМС является современной многопрофильной
              медицинской компанией, имеющей лицензию для оказания медицинских
              услуг более чем по тридцати профильным направлениям.
            </p>
            <p>
              На данный момент мы работаем в городе Мурманске и в городе
              Заполярный.
            </p>
            <p>
              У нас Вы можете пройти любые медицинские комиссии, лабораторные
              исследования, функциональную диагностику, получить консультации
              врачей специалистов, услуги процедурного кабинета, услуги по
              массажу, стоматологические услуги.
            </p>
            <p>
              Мы гарантируем Вам высокое качество, приемлемые цены и систему
              скидок, индивидуальный подход, внимание и доброжелательность наших
              специалистов.
            </p>
            <b>Наши основные преимущества:</b>
            <div class="BenefitsList">
              <InfoLine
                icon="check"
                title=" Мы находимся рядом с психоневрологическим диспансером"
              />
              <InfoLine
                icon="check"
                title="Получение справки в течении 15 мин"
              />
              <InfoLine icon="check" title="Цены Вас приятно удивят" />
              <InfoLine
                icon="check"
                title="Консультации врачей с выездом на дом"
              />
              <InfoLine
                icon="check"
                title="Запись к врачу в удобное для Вас время"
              />
            </div>
            <div className="About-Buttons">
              <Button
                theme="primary-white"
                title="Записаться на прием"
                type="navhashlink"
                icon="arrow-right"
                iconPosition="right"
                path="/zapis-na-priem"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
