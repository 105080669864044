import React from 'react'
import './Avatar.sass'
import a1 from './assets/a1.svg'
import a2 from './assets/a2.svg'
import a3 from './assets/a3.svg'
import noAv from './assets/no.svg'

const Avatar = React.forwardRef(({ asset = 'noAv' }, ref = null) => {
  const assets = {
    1: a1,
    2: a2,
    3: a3,
    noAv,
  }

  return (
    <div ref={ref} className="Avatar">
      <img src={assets[asset]} alt="" />
    </div>
  )
})

export default Avatar
