import React, { useState } from 'react'
import './Specialization.sass'
import Icon from '../../ui/Icon/Icon'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, isNull, orderBy } from 'lodash'
import { useContext } from 'react'
import { AuthContext } from '../..'
import { NavLink } from 'react-router-dom'

function Specialization() {
  const [services, setServices] = useState(null)

  const { cities } = useContext(AuthContext)
  const defaultCity = find(cities, ['isDefault', true])

  useEffect(() => {
    getCollection({
      path: 'settings/price/categories',
      docIdName: 'categoryId',
    }).then((result) => {
      setServices(
        orderBy(
          result.filter(
            (s) => s.cityId === defaultCity.cityId && s.isDeleted !== true
          ),
          ['pos']
        )
      )
    })
  }, [defaultCity])

  return (
    <div className="Specialization">
      {!isNull(services)
        ? services.map((s) => <SpecializationTile {...s} />)
        : 'loading'}
    </div>
  )
}

function SpecializationTile({ title = 'title', categoryId, icon = {} }) {
  return (
    <NavLink to={`/uslugi-i-ceny/${categoryId}`}>
      <div id={categoryId} className="SpecializationTile">
        <div className="Icon-Container">
          <img src={!isEmpty(icon) ? icon.publicUrl : icon} alt={title} />
          {/* <Icon name="user-md" /> */}
        </div>
        <p className="Body-Bold-2">{title}</p>
      </div>
    </NavLink>
  )
}

export default Specialization
