import React, { useEffect } from 'react'
import './Health.sass'
import TabGroup from '../../../ui/TabGroup/TabGroup'
import EmptyArea from '../../../ui/EmptyArea/EmptyArea'
import Button from '../../../ui/Button/Button'
import OrdersTable from './OrdersTable/OrdersTable'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../index'
import { db } from '../../../config/firebase'
import { isEmpty, isNull, find, cloneDeep } from 'lodash'
import Spinner from '../../../ui/Spinner/Spinner'
import { Route, withRouter } from 'react-router-dom'
import OrderData from './OrderData/OrderData'
import Media from 'react-media'
import OrdersList from './OrdersList/OrdersList'
import Devider from '../../../ui/Devider/Devider'
import EventTile from './components/EventTile/EventTile'
import { isAfter, isBefore } from 'date-fns'

function Health({ ...router }) {
  const { user, location, category } = useContext(AuthContext)

  const [orders, setOrders] = useState(null)
  const [tabs, setTabs] = useState([
    {
      id: 'active',
      title: 'Активные',
      statuses: ['KGpVTDMZlJxyxn9zenpy', 'mb0oZEnvz1eqUZ6FAY8m'],
      isActive: true,
      counter: 0,
      theme: 'primary',
    },
    {
      id: 'past',
      title: 'Прошедшие',
      statuses: ['e2QJKCyNal7CmLhFVOsZ', 'WCZpA64uC6lSVwnP24xq'],
      isActive: false,
      counter: 0,
      theme: 'internal-link',
    },
  ])

  useEffect(() => {
    const ordersListener = db.collection('schedule').onSnapshot((snap) => {
      const data = []
      snap.forEach((d) => data.push({ eventId: d.id, ...d.data() }))

      const userOrders = data.filter(
        (d) =>
          (!isEmpty(d.user) && d.user.userId === user.uid) ||
          (!isEmpty(d.members) && find(d.members, ['userId', user.uid]))
      )

      setOrders(userOrders)

      setTabs(
        tabs.map((t) => {
          if (t.id === 'active') {
            t.counter = userOrders.filter((o) =>
              isBefore(Date.now(), o.dateStart.seconds * 1000)
            ).length
          }
          if (t.id === 'past') {
            t.counter = userOrders.filter((o) =>
              isAfter(Date.now(), o.dateStart.seconds * 1000)
            ).length
          }

          return t
        })
      )
    })
    return () => {
      ordersListener()
    }
  }, [])

  const onTabClicked = (id) => {
    const tabsClone = cloneDeep(tabs)
    setTabs(
      tabsClone.map((t) => {
        t.isActive = t.id === id
        return t
      })
    )
  }

  const activeTab = find(tabs, ['isActive', true])

  // const addMore = () => {
  //   if (location.id && category.id) {
  //     router.history.push('/catalog')
  //   } else {
  //     router.history.push('/')
  //   }
  // }

  return (
    <div className="Health">
      <h2>Мое здоровье</h2>
      <div className="Health-Main">
        <HealtMainTile bgColor="#E0FAFF" />
        <HealtMainTile bgColor="#FFECD6" />
        <HealtMainTile bgColor="#F0F3FF" />
      </div>
      <Devider />
      <h2>События</h2>
      <TabGroup tabs={tabs} onClick={onTabClicked} />
      <div className="Health-EventList">
        {isNull(orders) ? (
          <div className="Spinner-Container">
            <Spinner type="popup" theme="gray" />
          </div>
        ) : isEmpty(orders) ? (
          <>
            <EmptyArea
              content={
                <span className="Caption-Regular">У вас еще нет событий</span>
              }
            />
          </>
        ) : (
          orders.map((o) => <EventTile {...o} />)
        )}
      </div>
    </div>
  )
}

function HealtMainTile({ bgColor }) {
  return (
    <div className="HealtMain-Tile" style={{ backgroundColor: bgColor }}>
      <p className="Title">Запись на прием</p>
    </div>
  )
}

export default withRouter(Health)
