import React from 'react'
import SitePage from '../../../SitePage/SitePage'
import './SuccessCheckout.sass'
import Button from '../../../../ui/Button/Button'
import il from './save.svg'
import SectionTitle from '../../../../ui/SectionTitle/SectionTitle'

function SuccessCheckout() {
  return (
    <SitePage>
      <div className="SuccessCheckout">
        <SectionTitle title="Ваша запись на прием успешно принята!" />
        <p>
          Ожидайте звонка от нашего оператора для подтверждения вашей
          онлай-записи
        </p>
        <div className="ImageContainer">
          <img src={il} alt="Success" />
        </div>
        <Button
          theme="primary"
          title="Перейти к записи"
          type="navhashlink"
          icon="arrow-right"
          iconPosition="right"
          path="/profile/health"
        />
      </div>
    </SitePage>
  )
}

export default SuccessCheckout
