import { find } from 'lodash'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../index'
import useWindowSize from '../../hooks/useWindowSize'
import Button from '../../ui/Button/Button'
import PopUp from '../../ui/PopUp/PopUp'
import './CityChooser.sass'
import updateDoc from '../../utils/db/updateDoc'
import setDoc from '../../utils/db/setDoc'

function CityChooser() {
  const { cities, setCities, cart } = useContext(AuthContext)
  const defaultCity = find(cities, ['isDefault', true])

  const [showCityChooser, setShowCityChooser] = useState(false)
  const [cityPref, setCityPref] = useState(null)

  useEffect(() => {
    // if (!localStorage.getItem('city')) {
    //   if (window.screen.width >= 576) setShowCityChooser(true)
    // }
  }, [])

  const onConfirm = () => {
    localStorage.setItem('city', cityPref.cityId)
    setCities(
      cities.map((c) => ({ ...c, isDefault: c.cityId === cityPref.cityId }))
    )

    if (cart.id && defaultCity.cityId !== cityPref.cityId) {
      setDoc({
        path: 'carts',
        docId: cart.id,
        data: {
          products: [],
          curStep: 'clinic',
        },
      })
    }
    setShowCityChooser(false)
  }
  const close = () => setShowCityChooser(false)

  return (
    <div className="CityChooser">
      <Button
        theme="internal-link"
        size={40}
        icon="map-marker-alt"
        iconWeight="solid"
        title={defaultCity.title}
        className="ChooserLink"
        onClick={() => setShowCityChooser(true)}
      />
      {showCityChooser && (
        <PopUp
          show
          close={close}
          title="Выбор города"
          className="CityChooserPopUp"
        >
          <div className="Cities-Container">
            {cities.map((c) => (
              <CityTile
                {...c}
                onClick={() => setCityPref(c)}
                isActive={cityPref && cityPref.cityId === c.cityId}
              />
            ))}
          </div>
          <div className="Buttons">
            <Button
              title="Сохранить"
              size={40}
              theme="primary"
              onClick={onConfirm}
            />
            <Button
              title="Отмена"
              size={40}
              theme="secondary"
              onClick={close}
            />
          </div>
        </PopUp>
      )}
    </div>
  )
}

const CityTile = ({ isActive = false, title, onClick }) => (
  <Button
    theme="secondary"
    size={40}
    title={title}
    className="CityTile"
    onClick={onClick}
    state={isActive ? 'active' : 'default'}
  />
)

export function defineDefaultCity({ cities }) {
  const savedCity = localStorage.getItem('city')

  if (savedCity && find(cities, ['cityId', savedCity]))
    return cities.map((c) => ({
      ...c,
      isDefault: c.cityId === savedCity,
    }))
  else {
    return cities.map((c) => ({
      ...c,
      isDefault: c.cityId === 'lvS3FE894YmD4VG0OpCR',
    }))
  }
}

export default CityChooser
