import React, { useState } from 'react'
import './OrderData.sass'
import Button from '../../../../ui/Button/Button'
import OrderDetails from '../../../../pages/Checkout/PaymentMethods/OrderDetails/OrderDetails'
import { useEffect } from 'react'
import getDoc from '../../../../utils/db/getDoc'
import { withRouter } from 'react-router-dom'
import { find, isEmpty } from 'lodash'
import Spinner from '../../../../ui/Spinner/Spinner'
import promiseAllValues from '../../../../utils/promiseAllValues'
import getCollection from '../../../../utils/db/getCollection'
import CancelOrderPopUp from './CancelOrderPopUp/CancelOrderPopUp'

function OrderData({ ...router }) {
  const [orderData, setOrderData] = useState(null)
  const [showCancel, setShowCancel] = useState(false)

  useEffect(() => {
    getDoc({
      path: 'orders',
      docId: router.match.params.orderId,
      docIdName: 'docId',
    }).then((order) =>
      promiseAllValues({
        shop: getDoc({
          path: 'shops',
          docId: order.pickupShop,
          docIdName: 'docId',
        }),
        cities: getCollection({ path: 'cities', docIdName: 'cityId' }),
        products: getCollection({ path: 'products', docIdName: 'productId' }),
        statuses: getCollection({
          path: 'config/orders/statuses',
          docIdName: 'statusId',
        }),
      }).then((result) => {
        const spot = find(result.shop.spots, ['spotId', order.pickupSpot])
        const city = find(result.cities, ['cityId', spot.spotCity])
        const status = find(result.statuses, ['statusId', order.status])

        const location = find(result.cities, ['cityId', spot.spotCity])
        setOrderData({
          docId: order.docId,
          location,
          cart: order,
          status,
          dates: {
            start: order.dates.start.seconds * 1000,
            end: order.dates.end.seconds * 1000,
          },
          sameDay: order.sameDay,
          city,
          data: {
            shop: result.shop,
            spot,
            products: result.products,
          },
        })
      })
    )
  }, [])

  return (
    <div className="OrderData">
      <Button
        theme="internal-link"
        title="Повернутися"
        icon="angle-left"
        iconPosition="left"
        iconWeight="regular"
        type="navlink"
        path="/profile/orders"
        size={32}
      />
      {!isEmpty(orderData) ? (
        <>
          {showCancel && (
            <CancelOrderPopUp
              docId={orderData.docId}
              number={orderData.cart.orderId}
              close={() => setShowCancel(false)}
            />
          )}
          <h2 className="OrderData-Title">
            {orderData.city.title}{' '}
            <div
              className="OrderStatus Body-Medium-2"
              style={{
                background: orderData.status.background,
                color: orderData.status.color,
              }}
            >
              {orderData.status.title}
            </div>
          </h2>
          <p className="Caption-Regular OrderNum">#{orderData.cart.orderId}</p>
          <OrderDetails {...orderData} />
          <div className="OrderData-Buttons">
            <Button
              title="Скасувати замовлення"
              size={40}
              onClick={() => setShowCancel(true)}
            />
          </div>
        </>
      ) : (
        <div className="Spinner-Container">
          <Spinner type="button" theme="gray" />
        </div>
      )}
    </div>
  )
}

export default withRouter(OrderData)
