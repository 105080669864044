import React, { useState, useEffect, useRef, useContext } from 'react'
import SearchInput, { CustomDdlistElement } from '../SearchInput/SearchInput'
import './SportChooser.sass'
import getCollection from '../../../utils/db/getCollection'
import Spinner from '../../../ui/Spinner/Spinner'
import { isEmpty, orderBy, find, cloneDeep } from 'lodash'
import { usePopUp } from '../../../hooks/usePopUp'
import { AuthContext } from '../../../index'

function SportChooser() {
  const [categories, setCategories] = useState(null)
  const butRef = useRef()
  const ddRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(butRef, ddRef)
  const { setCategory } = useContext(AuthContext)

  useEffect(() => {
    const activeCategory = find(categories, ['isActive', true])
    if (activeCategory) {
      setCategory(activeCategory)
    } else {
      setCategory({ id: null, title: null })
    }
  }, [categories])

  useEffect(() => {
    getCollection({
      docIdName: 'id',
      path: 'config/products/categories',
      whereQueries: [{ fieldPath: 'parentCategory', op: '==', value: 'root' }],
    }).then((result) => {
      setCategories(
        orderBy(result, ['position'], ['asc']).map((c) => {
          c.isActive = localStorage.getItem('category') === c.id
          return c
        })
      )
    })
  }, [])

  const onCategoryClicked = (categoryId) => {
    setShowPopUp(false)
    const categoriesClone = cloneDeep(categories)
    setCategories(
      categoriesClone.map((c) => {
        c.isActive = categoryId === c.id
        return c
      })
    )
  }

  const onValueDrop = () => {
    const categoriesClone = cloneDeep(categories)
    localStorage.removeItem('category')
    setCategories(
      categoriesClone.map((c) => {
        c.isActive = false
        return c
      })
    )
  }

  return (
    <div className="SportChooser">
      <SearchInput
        ref={butRef}
        title="Вид спорту"
        placeholder="Для чого вам спорядження?"
        dropdownElements={
          showPopUp ? (
            <div ref={ddRef} className="DropdownList_custom">
              <div className="List">
                {!isEmpty(categories) ? (
                  categories.map((c) => (
                    <CustomDdlistElement
                      {...c}
                      onClick={() => onCategoryClicked(c.id)}
                    />
                  ))
                ) : (
                  <Spinner type="popup" />
                )}
              </div>
            </div>
          ) : null
        }
        value={
          find(categories, ['isActive', true])
            ? find(categories, ['isActive', true]).title
            : null
        }
        onValueDrop={onValueDrop}
      />
    </div>
  )
}

export default SportChooser
