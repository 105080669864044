import { createForm } from '../../../../utils/forms/createForm'
import UserObject from '../../SignUp/functions/userObject'
import getFormValues from '../../../../utils/forms/getFormValues'
import setDoc from '../../../../utils/db/setDoc'

const signUpWithSocial = (result) => {
  let formData = {
    name: '',
    lastName: '',
    email: '',
    signUpType: result.additionalUserInfo.providerId,
  }
  const profile = result.additionalUserInfo.profile
  const uid = result.user.uid

  if (result.additionalUserInfo.providerId === 'google.com') {
    formData.name = profile.given_name
    formData.lastName = profile.family_name
    formData.email = profile.email
    //after signUpChanged
    formData.phoneVerified = true
  }

  const values = getFormValues({
    form: createForm({ formData, formPattern: new UserObject() }),
  })

  return setDoc({ path: 'users', docId: uid, data: values })
}

export default signUpWithSocial
