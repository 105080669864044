import React, { useContext, useEffect, useState } from 'react'
import './EventTile.sass'
import DoctorAvatar from '../../../../../ui/DoctorAvatar/DoctorAvatar'
import Status from '../../../../../ui/Status/Status'
import Devider from '../../../../../ui/Devider/Devider'
import Button from '../../../../../ui/Button/Button'
import {
  formatDateAsDayMontYearHHMM,
  formatDateAsDayMonthNameYear,
} from '../../../../../utils/date/dateFormat'
import promiseAllValues from '../../../../../utils/promiseAllValues'
import getCollection from '../../../../../utils/db/getCollection'
import { find, isEmpty } from 'lodash'
import Spinner from '../../../../../ui/Spinner/Spinner'
import { AuthContext } from '../../../../..'
import CancelEventPopUp from '../CancelEventPopUp/CancelEventPopUp'
import GetDocPopUp from '../GetDocPopUp/GetDocPopUp'

function EventTile(data) {
  const { cities, user } = useContext(AuthContext)
  const [parsedData, setParsedData] = useState(null)
  const userDataIngGroup = find(data.members, ['userId', user.uid])
  const [showPopUp, setShowPopUp] = useState(false)

  useEffect(() => {
    promiseAllValues({
      doctors: getCollection({
        path: 'doctors',
        docIdName: 'doctorId',
      }),
      specializations: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
      }),
    }).then((result) => {
      setParsedData({
        ...data,
        doctorData: find(result.doctors, ['doctorId', data.doctorId]),
        specializationData: find(result.specializations, [
          'categoryId',
          data.specialization,
        ]),
        clinicData: find(cities, ['cityId', data.cityId]),
        status:
          data.eventType === 'group'
            ? !isEmpty(userDataIngGroup) && userDataIngGroup.status
            : data.status,
      })
    })
  }, [data])

  return (
    <div className="EventTile">
      {data.eventId}
      {showPopUp === 'cancelEvent' && (
        <CancelEventPopUp
          eventId={data.eventId}
          eventType={data.eventType}
          close={() => setShowPopUp(false)}
        />
      )}
      {showPopUp === 'getDoc' && (
        <GetDocPopUp
          eventId={data.eventId}
          eventType={data.eventType}
          close={() => setShowPopUp(false)}
        />
      )}
      {!isEmpty(parsedData) ? (
        <>
          <p className="Title-Header">
            <span className="Title">{parsedData.specializationData.title}</span>
            <span className="Date">
              {formatDateAsDayMonthNameYear(data.created.seconds * 1000)}
            </span>
          </p>

          <div className="Content">
            <div className="DoctorInfo">
              <DoctorAvatar
                src={
                  find(parsedData.doctorData.photos, ['isAvatar', true])
                    ? find(parsedData.doctorData.photos, ['isAvatar', true])
                        .publicUrl
                    : null
                }
                title={parsedData.doctorData.title}
              />
              <div className="Data">
                <p className="Body-Bold-2">{parsedData.doctorData.title}</p>
                <p className="Body-Regular-2">
                  {parsedData.doctorData.description}
                </p>
              </div>
            </div>
            <div className="EventInfo">
              <p className="Body-Bold-2">Дата записи</p>
              <p className="Body-Regular-2">
                {' '}
                {formatDateAsDayMontYearHHMM(data.dateStart.seconds * 1000)}
              </p>
              <p className="ClinicAddress Caption-Regular">
                {parsedData.clinicData.contacts.address}
              </p>
            </div>
            {parsedData.status === 'booked' ? (
              <Status
                title="Предварительная запись"
                icon="clock"
                bgColor="#FFE8AD"
                color="#AD6600"
              />
            ) : parsedData.status === 'confirmed' ? (
              <Status
                title="Запись подтверждена"
                icon="check"
                bgColor="#1AAA55"
                color="#ffffff"
              />
            ) : parsedData.status === 'done' ? (
              <Status
                title="Услуга оказана"
                icon="check"
                bgColor="#1AAA55"
                color="#ffffff"
              />
            ) : parsedData.status === 'awaitResult' ? (
              <Status
                title="Ожидание результата"
                icon="clock"
                bgColor="#FFE8AD"
                color="#AD6600"
              />
            ) : (
              <Status
                title="Запись отменена"
                icon="clock"
                bgColor="#FFE8AD"
                color="#AD6600"
              />
            )}
          </div>
        </>
      ) : (
        <Spinner type="module" />
      )}

      {/* <Devider />
      <div className="EventTile-Details">
        <p className="Body-Bold-2">Детали заказа</p>
        <div className="Details-List">
          <p>Мед.комисия для выезжающих за границу (Ф.082У) - 2400 руб.</p>
          <p>Мед.комисия для выезжающих за границу (Ф.082У) - 2400 руб.</p>
        </div>
      </div> */}
      <Devider />

      <div className="Total">
        <div className="Total-Data">
          {/* <p className="Body-Bold-2">
            Итого к оплате: <span className="Body-Regular-2">1500 руб</span>
          </p> */}
          {/* <Status title="Не оплачено" color="#DB3B21" borderColor="#DB3B21" /> */}
        </div>
        <div className="Total-Controlls">
          {/* <Button
            title="Оплатить онлайн"
            theme="secondary"
            className="PayOnlineButton"
            size={24}
          /> */}
          {!isEmpty(parsedData) &&
            parsedData.status === 'done' &&
            parsedData.specializationData.givesDoc && (
              <Button
                title="Получить результат online"
                theme="primary"
                size={32}
                onClick={() => setShowPopUp('getDoc')}
              />
            )}
          {!isEmpty(parsedData) && parsedData.status === 'booked' && (
            <Button
              title="Отменить запись"
              theme="internal-link"
              size={24}
              onClick={() => setShowPopUp('cancelEvent')}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EventTile
