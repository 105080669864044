import updateDoc from '../../../utils/db/updateDoc'

const updateProducts = ({ cartId, products }) => {
  updateDoc({
    path: 'carts',
    docId: cartId,
    data: { products, created: new Date(), updated: new Date() },
  }).catch((e) => {
    console.log('🚀 ~ file: ProductTile.jsx ~ line 80 ~ e', e)
  })
}

export default updateProducts
