import React, { useRef, useContext, useEffect } from 'react'
import { usePopUp } from '../../../hooks/usePopUp'
import Avatar from '../Avatar/Avatar'
import './ProfileButton.sass'
import ProfilePopUp from './ProfilePopUp/ProfilePopUp'
import { AuthContext } from '../../../index'
import Icon from '../../../ui/Icon/Icon'

function ProfileButton() {
  const aRef = useRef()
  const pRef = useRef()
  const { user, showAuth } = useContext(AuthContext)
  const [showPopUp, setShowPopUp] = usePopUp(aRef, pRef)

  useEffect(() => {
    if (showAuth) setShowPopUp(false)
  }, [showAuth])

  return (
    <div className="ProfileButton-Container">
      <div className="ProfileButton-Body" ref={aRef}>
        <Icon name="user-circle" weight="regular" />
        <span className="Caption-Medium">{user ? 'Мой профиль' : 'Вход'}</span>
      </div>
      {showPopUp && (
        <ProfilePopUp ref={pRef} close={() => setShowPopUp(false)} />
      )}
    </div>
  )
}

export default ProfileButton
