import React from 'react'
import './SitePage.sass'
import Footer from '../../components/Footer/Footer'
import Top from '../../components/Top/Top'
import Nav from '../../components/Nav/Nav'
import Contacts from '../../components/Contacts/Contacts'

function SitePage({ children }) {
  return (
    <div className="SitePage">
      <Top />
      <Nav />
      {children}
      {/* <Contacts /> */}
      <Footer />
    </div>
  )
}

export default SitePage
