import React, { useContext, useEffect } from 'react'
import './News.sass'
import askImg from './askImg.jpg'
import Button from '../../ui/Button/Button'
import { AuthContext } from '../..'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { useState } from 'react'
import getCollection from '../../utils/db/getCollection'
import { formatDateAsDayMontYearHHMM } from '../../utils/date/dateFormat'
import { find, isEmpty, orderBy } from 'lodash'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom'
import Spinner from '../../ui/Spinner/Spinner'

function News() {
  const { setShowContactUs } = useContext(AuthContext)
  const [news, setNews] = useState(null)

  useEffect(() => {
    getCollection({ path: 'news', docIdName: 'newsId' }).then((data) =>
      setNews(
        orderBy(
          data.filter((d) => !d.isDeleted),
          ['created'],
          ['desc']
        )
      )
    )
  }, [])

  return (
    <div id="novosti" className="News">
      <div className="DefaultContainer">
        <SectionTitle title="Новости и акции" />
        <div className="News-Container">
          {!isEmpty(news) ? (
            <>
              <div className="TopNews">
                {news.slice(0, 3).map((n, i) => (
                  <NewsTile type={i === 0 ? 'BigTopType' : 'TopType'} {...n} />
                ))}
              </div>
              <div className="OtherNews">
                <h4>Другое</h4>
                {news.slice(3).map((n, i) => (
                  <NewsTile type={'DefaultType'} {...n} />
                ))}
              </div>
            </>
          ) : (
            <Spinner type="module" />
          )}

          <div className="AskBlock">
            <img className="AskImg" src={askImg} alt="Спроси у АСД" />
            <h3>Задать вопрос</h3>
            <p className="Body-Regular-1">
              Наши специалисты с радостью ответят на все вопросы
            </p>
            <div className="Buttons">
              <Button
                theme="primary-white"
                title="Задать вопрос"
                type="button"
                icon="envelope"
                iconWeight="solid"
                iconPosition="right"
                onClick={() =>
                  setShowContactUs({
                    show: true,
                    title: 'Задать вопрос',
                    emailSubject: '[Вопрос] Новый вопрос от пользователя сайта',
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function NewsTile({
  newsId,
  type,
  title,
  photos,
  shortNews = '',
  longNews = '',
  created,
}) {
  const avatar = find(photos, ['isAvatar', true])

  return (
    <div className={`NewsTile ${type}`}>
      <NavLink to={`/novosti/${newsId}`}>
        {avatar && (
          <div className="NewsTile-Image">
            <img src={avatar.publicUrl} alt="title" />
          </div>
        )}
        <div className="NewsTile-Content">
          <h3 className="Title">{title}</h3>
          <p className="Caption-Regular Date">
            {created && formatDateAsDayMontYearHHMM(created.seconds * 1000)}
          </p>
          <p className="Body-Regular-1 NewsText">{parse(shortNews)}</p>
        </div>
      </NavLink>
    </div>
  )
}

export default News
