import React, { useContext } from 'react'
import './Stepper.sass'
import Icon from '../../ui/Icon/Icon'
import { withRouter } from 'react-router-dom'
import updateDoc from '../../utils/db/updateDoc'
import { AuthContext } from '../..'

function Stepper({ steps, width, ...router }) {
  const oneStepPercentage = 100 / (steps.length - 1)
  const progress =
    oneStepPercentage * steps.filter((s) => s.status === 'done').length

  return (
    <div className="Stepper" style={{ width }}>
      <div
        className="StepBar"
        style={{
          background:
            progress === 0
              ? `#CAD0D5`
              : `linear-gradient(to right, #6FD44B ${progress}%, #CAD0D5 ${
                  100 - progress
                }% 100%)`,
        }}
      />
      {steps.map((s, i) => (
        <Step
          {...s}
          firstStep={i === 0}
          lastStep={i === steps.length - 1}
          router={router}
        />
      ))}
    </div>
  )
}

function Step({ id, status, title, firstStep, lastStep }) {
  const { cart } = useContext(AuthContext)

  const onStepClicked = () => {
    if (status === 'notActive') return null

    return updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { curStep: id },
    })
  }

  return (
    <div
      className={[
        'Step',
        `Step_status_${status}`,
        firstStep ? 'FirstStep' : '',
        lastStep ? 'LastStep' : '',
      ].join(' ')}
      onClick={onStepClicked}
    >
      <div className="Marker">{status === 'done' && <Icon name="check" />}</div>
      <div className="Title Caption-Medium">{title}</div>
    </div>
  )
}

export default withRouter(Stepper)
