import React, { useContext, useRef } from 'react'
import Icon from '../../../ui/Icon/Icon'
import './CartButton.sass'
import { AuthContext } from '../../../index'
import CartPopUp from '../CartPopUp/CartPopUp'
import { usePopUp } from '../../../hooks/usePopUp'
import { isEmpty, sum } from 'lodash'

function CartButton() {
  const { cart } = useContext(AuthContext)
  const butRef = useRef()
  const pRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(butRef, pRef)

  return (
    <div className="CartButton-Container">
      <div ref={butRef} className="CartButton-Body">
        <div className="CartButton">
          {!isEmpty(cart.products) ? (
            <span className="CartCounter Overline-Medium">
              {cart.products.length}
            </span>
          ) : null}
          <Icon name="briefcase-medical" weight="regular" />
          <span className="Caption-Medium">Корзина</span>
        </div>
      </div>
      {showPopUp && (
        <div ref={pRef}>
          <CartPopUp close={() => setShowPopUp(false)} />
        </div>
      )}
    </div>
  )
}

export default CartButton
