import React from 'react'
import { functions } from '../../../../config/firebase'
import { renderEmail } from 'react-html-email'
import PasswordResetEmail from '../../../../components/Emails/PasswordResetEmail'

const sendPasswordRecoveryEmail = ({ to, recoveryCode }) => {
  const func = functions.httpsCallable('emailSender')
  const messageHtml = renderEmail(
    <PasswordResetEmail recoveryCode={recoveryCode} />
  )

  return func({
    to,
    subject: 'Установите новый пароль',
    html: messageHtml,
  })
}

export default sendPasswordRecoveryEmail
