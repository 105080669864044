import React from 'react'
import { Item, Span } from 'react-html-email'
import EmailBody from './EmailBody/EmailBody'
import EmailActionButton from './EmailActionButton'

function PasswordResetEmail({ recoveryCode }) {
  return (
    <EmailBody>
      <Item align="center">
        <Span
          style={{
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          Установите новый пароль
        </Span>
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '8px',
        }}
      >
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Мы получили запрос на восстановление Вашего пароля. Воспользуйтесь
          ссылкой ниже, или скопируйте ее и вставьте в свой браузер, чтобы
          установить новый пароль.
        </Span>
      </Item>
      <EmailActionButton
        style={{
          paddingTop: '24px',
          alignSelf: 'center',
        }}
        title="Установить новый пароль"
        link={`https://asdnord.ru/password-recovery?recoveryCode=${recoveryCode}`}
      />
    </EmailBody>
  )
}

export default PasswordResetEmail
