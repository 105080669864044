import { isEmpty } from 'lodash'
import uniqid from 'uniqid'
import getCollection from '../../../../utils/db/getCollection'
import updateDoc from '../../../../utils/db/updateDoc'
import getOnlyNumbers from '../../../../utils/forms/getOnlyNumbers'
import sendPasswordRecoverySms from './sendPasswordRecoverySms'
import sendPasswordRecoveryEmail from './sendPasswordRecoveryEmail'

function sendRecoveryLink({ to, passwordRecoveryPage = null }) {
  const recoveryCode = uniqid('recovery-code-')
  const recoveryType =
    to[0] === '+'
      ? {
          field: 'phone',
          func: () =>
            sendPasswordRecoverySms({
              to: getOnlyNumbers(to),
              recoveryCode,
            }),
          value: getOnlyNumbers(to),
        }
      : {
          field: 'email',
          func: () => sendPasswordRecoveryEmail({ to, recoveryCode }),
          value: to,
        }

  return getCollection({
    path: 'users',
    docIdName: 'userId',
    whereQueries: [
      {
        fieldPath: recoveryType.field,
        op: '==',
        value: recoveryType.value,
      },
    ],
  })
    .then((data) => {
      if (!isEmpty(data)) {
        return updateDoc({
          path: 'users',
          docId: data[0].userId,
          data: {
            passwordRecoveryCode: recoveryCode,
            ...(passwordRecoveryPage ? { passwordRecoveryPage } : {}),
          },
        }).then((uid) => {
          if (uid) {
            return recoveryType.func()
          } else {
            return null
          }
        })
      }
      return null
    })
    .catch((e) => {
      console.log(
        '🚀 ~ file: sendRecoveryLink.js ~ line 16 ~ sendRecoveryLink ~ e',
        e
      )
    })
}

export default sendRecoveryLink
