import React, { useEffect, useState } from 'react'
import './OrdersTable.sass'
import { withRouter } from 'react-router-dom'
import SceneTable from '../../../../ui/Table/SceneTable/SceneTable'
import promiseAllValues from '../../../../utils/promiseAllValues'
import getCollection from '../../../../utils/db/getCollection'
import { find, isEmpty } from 'lodash'
import getFormattedValue from '../../../HomeSearch/DateChooser/functions/getFormattedValue'
import { formatDateAsDayMonthYear } from '../../../../utils/date/dateFormat'

function OrdersTable({ orders, ...router }) {
  const [data, setData] = useState(null)
  const [parsedOrders, setParsedOrders] = useState([])

  useEffect(() => {
    promiseAllValues({
      shops: getCollection({ path: 'shops', docIdName: 'shopId' }),
      products: getCollection({ path: 'products', docIdName: 'productId' }),
      cities: getCollection({ path: 'cities', docIdName: 'cityId' }),
      statuses: getCollection({
        path: 'config/orders/statuses',
        docIdName: 'statusId',
      }),
    }).then((result) => setData(result))
  }, [])

  useEffect(() => {
    if (!isEmpty(data)) {
      setParsedOrders(
        orders.map((o) => {
          const shop = find(data.shops, ['shopId', o.pickupShop])
          const spot = find(shop.spots, ['spotId', o.pickupSpot])
          const city = find(data.cities, ['cityId', spot.spotCity])
          const status = find(data.statuses, ['statusId', o.status])

          o.spotFullAddress = `${spot.title}, м. ${city.title}, ${spot.spotAddress}`
          o.city = city.title
          o.statusData = status
          return o
        })
      )
    }
  }, [orders, data])

  return (
    <div className="OrdersTable">
      <SceneTable
        editOnDoubleClick
        header={[
          ['№', 50],
          ['Локація'],
          ['Статус'],
          ['Термін оренди'],
          ['Пункт прокату'],
          ['Дата оформлення'],
          ['Всього'],
        ]}
        body={parsedOrders.map((o) => [
          { id: o.docId },
          <span className="Caption-Regular" style={{ color: '#737A80' }}>
            {o.orderId}
          </span>,
          <span className="OrderCity">{o.city}</span>,
          <span
            className="OrderStatus Body-Medium-2"
            style={{
              background: o.statusData.background,
              color: o.statusData.color,
            }}
          >
            {o.statusData.title}
          </span>,
          <span className="Caption-Regular">
            {getFormattedValue({
              start: o.dates.start.seconds * 1000,
              end: o.dates.end.seconds * 1000,
              sameDay: o.sameDay,
            })}
          </span>,
          <span className="Caption-Regular">{o.spotFullAddress}</span>,
          <span className="Caption-Medium">
            {formatDateAsDayMonthYear(o.created.seconds * 1000)}
          </span>,
          <span className="Caption-Medium">{o.priceToPay} ₴</span>,
        ])}
        options={[
          {
            return: 'docId',
            type: 'edit',
            icon: 'pencil-alt',
            label: 'Редаг.',
            clicked: (orderId) =>
              router.history.push(`/profile/orders/${orderId}`),
          },
        ]}
        editOnDoubleClick
      />
    </div>
  )
}

export default withRouter(OrdersTable)
