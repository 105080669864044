import { differenceInDays, format } from 'date-fns'
import ru from 'date-fns/locale/ru'

const formatDateAsMonthNameAndYear = (date) =>
  format(date, 'LLLL, yyyy', { locale: ru })
const formatDateAsDayMontYearHHMM = (date) =>
  format(date, 'dd MMMM yyyy в HH:mm', { locale: ru })
const formatDateAsDayMMYearHHMM = (date) =>
  format(date, 'dd.LL.yyyy в HH:mm', { locale: ru })
const formatDateAsDayMonthYear = (date) =>
  format(date, 'dd.LL.yyyy', { locale: ru })
const formatDateAsDayMonthNameYear = (date) =>
  format(date, 'dd LLLL yyyy', { locale: ru })
const formatDateAsMonthNameYear = (date) =>
  format(date, 'LLLL yyyy', { locale: ru })
const formatDateAsMonthName = (date) => format(date, 'LLLL', { locale: ru })
const formatDateAsYYYYMMDD = (date) =>
  format(date, 'yyyy-LL-dd', { locale: ru })
const formatDateAsHHMM = (date) => format(date, 'HH:mm', { locale: ru })
const formatDurationDate = (date1, date2) =>
  `${format(date1, 'dd.LL.yyyy, с HH:mm', { locale: ru })} до ${format(
    date2,
    'HH:mm',
    { locale: ru }
  )}`

const formatDateForDateChooser = (start, end, sameDay) =>
  `${format(start, 'dd', { locale: ru })} - ${format(end, 'dd MMMM', {
    locale: ru,
  })} (${
    differenceInDays(end, start) === 0
      ? 1
      : sameDay
      ? differenceInDays(end, start)
      : differenceInDays(end, start) + 1
  } дні прокату)`
const formatDateWithMonthForDateChooser = (start, end, sameDay) =>
  `${format(start, 'dd MMMM', { locale: ru })} - ${format(end, 'dd MMMM', {
    locale: ru,
  })} (${
    differenceInDays(end, start) === 0
      ? 1
      : sameDay
      ? differenceInDays(end, start)
      : differenceInDays(end, start) + 1
  } дні прокату)`
export {
  formatDateAsMonthNameAndYear,
  formatDateAsDayMMYearHHMM,
  formatDateAsDayMontYearHHMM,
  formatDateAsDayMonthNameYear,
  formatDateAsMonthNameYear,
  formatDateAsMonthName,
  formatDateAsYYYYMMDD,
  formatDateAsHHMM,
  formatDateAsDayMonthYear,
  formatDateForDateChooser,
  formatDateWithMonthForDateChooser,
  formatDurationDate,
}
