import React from 'react'
import './CheckoutButtons.sass'
import Button from '../../../../ui/Button/Button'

function CheckoutButtons({
  onPrevStep = null,
  onNextStep = null,
  isActiveNextStep = false,
  isLoading = false,
}) {
  return (
    <div className="CheckoutButtons">
      <div className="CheckoutButtons-Container DefaultContainer">
        {onPrevStep ? (
          <Button
            theme="secondary"
            title="Предыдущий шаг"
            type="button"
            size={48}
            path="#calc"
            onClick={onPrevStep}
          />
        ) : (
          <span className="EmptyStep"></span>
        )}

        {onNextStep ? (
          <Button
            theme="primary"
            title="Следующий шаг"
            type="button"
            size={48}
            path="#calc"
            onClick={onNextStep}
            isLoading={isLoading}
            disabled={!isActiveNextStep}
          />
        ) : (
          <span className="EmptyStep"></span>
        )}
      </div>
    </div>
  )
}

export default CheckoutButtons
