import React, { useContext, useEffect, useState } from 'react'
import CartOnPage from '../../components/Cart/CartOnPage/CartOnPage'
import PriceTable from '../../components/Price/PriceTable'
import Button from '../../ui/Button/Button'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import SitePage from '../SitePage/SitePage'
import './MedComissions.sass'
import promiseAllValues from '../../utils/promiseAllValues'
import getCollection from '../../utils/db/getCollection'
import { cloneDeep, find, isEmpty, orderBy } from 'lodash'
import { AuthContext } from '../..'
import { ServicesPrice } from '../Services/Services'
import i1 from './assets/sea.png'
import i2 from './assets/guard.png'
import i3 from './assets/driver.png'
import i4 from './assets/work.png'
import i5 from './assets/student.png'
import i6 from './assets/sport.png'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'

function MedComissions() {
  const { cities } = useContext(AuthContext)
  const defaultCity = find(cities, ['isDefault', true])
  const [cats, setCats] = useState([
    {
      title: 'Медкомиссия для плавсостава',
      tag: 'sea',
      isActive: true,
      icon: i1,
    },
    {
      title: 'Медкомиссия для частного охранника',
      tag: 'guard',
      icon: i2,
    },
    {
      title: 'Медкомиссия на права',
      tag: 'driver',
      icon: i3,
    },
    {
      title: 'Медкомиссия на работу',
      tag: 'n29',
      icon: i4,
    },
    {
      title: 'Медкомиссия для абитуриентов',
      tag: 'student',
      icon: i5,
    },
    {
      title: 'Медкомиссия для спорта',
      tag: 'sport',
      icon: i6,
    },
  ])
  const [price, setPrice] = useState(null)

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
      }),
      services: getCollection({
        path: 'settings/price/services',
        docIdName: 'serviceId',
      }),
    }).then((result) => {
      const catsByCity = result.categories.filter(
        (c) =>
          c.cityId === defaultCity.cityId &&
          c.isDeleted !== true &&
          !isEmpty(c.groupTag)
      )

      const servicesByCity = result.services.filter((s) =>
        find(catsByCity, ['categoryId', s.categoryId])
      )

      setPrice(
        orderBy(
          catsByCity.map((category) => ({
            ...category,
            services: servicesByCity.filter(
              (s) => s.categoryId === category.categoryId
            ),
          })),
          ['pos'],
          ['asc']
        )
      )
    })
  }, [])

  const onComissionClicked = (id) => {
    const clone = cloneDeep(cats)
    clone.forEach((c) => (c.isActive = c.tag === id))
    setCats(clone)
  }

  const activeCat = find(cats, ['isActive', true])
  const catPrice = !isEmpty(price)
    ? price.filter((p) => p.groupTag === activeCat.tag)
    : null
  return (
    <SitePage>
      <div className="MedComissions">
        <div className="MedComissions-Container DefaultContainer">
          <SectionTitle title="Записаться на медкомиссию" />
          {!isEmpty(price) && (
            <>
              <div className="MedComissions-Tiles">
                {cats.map((c, i) => (
                  <ComissionTile {...c} onClick={onComissionClicked} />
                ))}
              </div>
              <div id="comission" className="MedComissions-Content">
                <div className="MedComissions-Page">
                  {/* <h3>{activeCat.title}</h3> */}
                  <ServicesPrice
                    price={catPrice}
                    openPriceId={
                      !isEmpty(catPrice) ? catPrice[0].categoryId : null
                    }
                  />
                </div>
                <CartOnPage />
              </div>
            </>
          )}
        </div>
      </div>
    </SitePage>
  )
}

const ComissionTile = ({ tag, title, icon, isActive, onClick }) => {
  return (
    <NavHashLink
      to={`#comission`}
      smooth
      scroll={(el) => scrollWithOffset(el, -64)}
    >
      <div
        className={`ComissionTile ${isActive && 'ComissionTile_theme_active'}`}
        onClick={() => onClick(tag)}
      >
        <div className="IconContainer">
          <img src={icon} alt={title} />
        </div>
        <div className="Title">{title}</div>
      </div>
    </NavHashLink>
  )
}

export default MedComissions
