import React from 'react'
import { Item, Span } from 'react-html-email'
import EmailBody from './EmailBody/EmailBody'

function VerificationEmail({ code = 'test' }) {
  return (
    <EmailBody>
      <Item
        align="center"
        style={{
          paddingBottom: '24px',
        }}
      >
        <Span
          style={{
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          Ваш код для подтверждения E-mail
        </Span>
      </Item>

      <Item
        align="center"
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          paddingTop: '24px',
          paddingBottom: '24px',
          backgroundColor: '#F1F7F9',
          borderRadius: '8px',
        }}
      >
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Введите этот код: <b>{code}</b>
        </Span>
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      ></Item>
    </EmailBody>
  )
}

export default VerificationEmail
