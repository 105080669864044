import React, { useState, useContext, useEffect } from 'react'
import './PhoneConfirmation.sass'
import { createForm } from '../../../utils/forms/createForm'
import FormRender from '../../../utils/forms/render/FormRender'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import Button from '../../../ui/Button/Button'
import CodeInput from 'react-verification-code-input'
import { AuthContext } from '../../../index'
import formatPhoneFromNumber from './functions/formatPhoneFromNumber'
import successIconAnimated from './success.gif'
import { differenceInMilliseconds } from 'date-fns'
import Countdown from 'react-countdown'
import genNewConfirmationSms from './functions/genNewConfirmationSms'
import updateDoc from '../../../utils/db/updateDoc'
import getFormValues from '../../../utils/forms/getFormValues'
import isFormValid from '../../../utils/forms/validation/isFormValid'

function PhoneConfirmation() {
  const { user } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        phone: {
          field: {
            fieldId: 'phone',
            fieldType: 'input',
            inputType: 'phone',
            mask: 'phone',
            label: 'Телефон',
            placeholder: '+7(XXX) XXX-XX-XX',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    const values = getFormValues({ form })

    if (!isFormValid({ form })) {
      return setShowErrors(true)
    } else {
      setShowErrors(false)
      setIsLoading(true)
      updateDoc({ path: 'users', docId: user.uid, data: values }).catch((e) => {
        console.log(
          '🚀 ~ file: PhoneConfirmation.jsx ~ line 54 ~ onSubmit ~ e',
          e
        )
      })
    }
  }

  return (
    <form onSubmit={onSubmit} className="PhoneConfirm-Form">
      <p className="Text Body-Regular-2">
        Для завершения регистрации введите номер телефона. Это почти последний
        шаг:)
      </p>
      <FormRender
        sections={[{ fields: ['phone'] }]}
        form={form}
        setForm={setForm}
        errors={showErrors}
      />
      <Button
        title="Отправить код"
        theme="primary"
        size={40}
        isLoading={isLoading}
        onClick={onSubmit}
        type="submit"
      />
    </form>
  )
}

export function PhoneCodeConfirmation({ onCodeConfirmed }) {
  const { user } = useContext(AuthContext)
  const [isCodeVerified, setIsCodeVerified] = useState(null)
  const [newSmsAvaliableAt, setNewSmsAvailableAt] = useState(Date.now() - 10000)

  useEffect(() => {
    const nextSmsTime = +localStorage.getItem('newSmsAvaliableAt')
    const newSmsDateDif = differenceInMilliseconds(nextSmsTime, Date.now())

    if (nextSmsTime && newSmsDateDif > 0) {
      setNewSmsAvailableAt(nextSmsTime)
    } else {
      sendNewSms({ setNewSmsAvailableAt, uid: user.uid, phone: user.phone })
    }
  }, [])

  const checkCodeInput = (code) => {
    if (user.phoneVerificationCode === code) {
      setIsCodeVerified(true)
      return setTimeout(
        () =>
          updateDoc({
            path: 'users',
            docId: user.uid,
            data: { phoneVerified: true, phoneVerificationCode: null },
          }).then(() => onCodeConfirmed()),
        500
      )
    }
    return setIsCodeVerified(false)
  }

  return (
    <form action="" className="PhoneConfirm-CodeForm">
      <p className="Text Body-Regular-2">
        {/* Мы отправили СМС на номер <b>{formatPhoneFromNumber(user.phone)}</b> */}
        Мы отправили сообщение с кодом для подтверждения Вашего E-mail:{' '}
        <b>{user.email}</b>
      </p>
      <div className="CodeInputBlock">
        <div>
          <CodeInput
            type="number"
            fields={4}
            autoFocus
            className={[
              'ConfirmCodeInput',
              ...(isCodeVerified === false
                ? ['ConfirmCodeInput_status_fail']
                : ['']),
            ].join(' ')}
            onChange={() => setIsCodeVerified(null)}
            onComplete={checkCodeInput}
          />
          {isCodeVerified === false && (
            <p className="ConfirmCodeError Overline-Regular">
              Вы ввели неверный код
            </p>
          )}
        </div>
        {isCodeVerified === true && (
          <img
            src={successIconAnimated}
            alt="Success"
            className="SuccessIcon"
          />
        )}
      </div>
      <p className="NewCode-Cotnainer Caption-Medium">
        Не получили код?{' '}
        <Countdown
          key={newSmsAvaliableAt}
          date={newSmsAvaliableAt}
          renderer={(props) => (
            <SendSmsButton
              {...props}
              setNewSmsAvailableAt={setNewSmsAvailableAt}
              uid={user.uid}
              phone={user.phone}
              email={user.email}
            />
          )}
        />
      </p>
    </form>
  )
}

const SendSmsButton = ({
  seconds,
  completed,
  setNewSmsAvailableAt,
  uid,
  phone,
  email,
}) => {
  if (completed) {
    return (
      <Button
        theme="internal-link"
        title="Отправить еще раз"
        size={24}
        onClick={() => sendNewSms({ setNewSmsAvailableAt, uid, phone, email })}
      />
    )
  } else {
    return (
      <span className="NotActiveLink">
        Отправить еще раз через {seconds < 10 && '0'}
        {seconds} сек...
      </span>
    )
  }
}

const sendNewSms = ({ setNewSmsAvailableAt, uid, phone, email }) => {
  const newSmsDate = Date.now() + 59000
  localStorage.setItem('newSmsAvaliableAt', newSmsDate)
  setNewSmsAvailableAt(newSmsDate)

  genNewConfirmationSms({
    uid,
    phoneNumber: phone,
    email,
  }).then((result) => {
    console.log('sendNewSms -> result', result)
  })
}

export default PhoneConfirmation
