import { functions } from '../config/firebase'

const sendSms = ({ phone, message }) => {
  const func = functions.httpsCallable('serviceRequestOnCall')
  return func({
    method: 'post',
    url: 'https://smsc.ru/sys/send.php',
    params: {
      login: 'asdmc',
      psw: 'Ao9}VRc5qf',
      // sender: 'ASDMC',
      phones: phone,
      mes: message,
    },
  }).then((response) => {
    console.log(
      '🚀 ~ file: sendSms.js ~ line 16 ~ sendSms ~ response',
      response
    )
    return response.data
  })
}

export default sendSms
