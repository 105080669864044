import React from 'react'
import Specialization from '../Specialization/Specialization'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './HomeSpecialization.sass'

function HomeSpecialization() {
  return (
    <section id="napravleniya" className="HomeSpecialization">
      <div className="DefaultContainer">
        <SectionTitle title="Направления" />
        <Specialization />
      </div>
    </section>
  )
}

export default HomeSpecialization
