import React from 'react'
import { Item, Span } from 'react-html-email'
import EmailBody from './EmailBody/EmailBody'

function CheckoutEmail({ date, services }) {
  return (
    <EmailBody>
      <Item align="center">
        <Span
          style={{
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          Запись на прием
        </Span>
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      >
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Вы успешно записались на прием: <b>{date}</b>
          <br />
          <b>{services}</b>
          <br />
          Пожалуйста, ожидайте подтверждения записи от специалиста.
          <br />
          <br />
          <b>При визите на прием иметь при себе: Паспорт, СНИЛС.</b>
        </Span>
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      >
        <Span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Отменить запись на прием возможно за 24 часа в{' '}
          <a
            href="https://asdnord.ru/profile/health"
            style={{ color: '#09A8CA' }}
          >
            личном кабинете
          </a>
          .
        </Span>
      </Item>
    </EmailBody>
  )
}

export default CheckoutEmail
