import React from 'react'
import './CartItemValued.sass'
import GenderIcon from '../../../pages/Catalog/ProductTile/GenderIcon/GenderIcon'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { find, isEmpty } from 'lodash'

function CartItem({
  assignment,
  options,
  optionsValues,
  productData,
  productPrice,
}) {
  console.log('🚀 ~ file: CartItemValued.jsx ~ line 15 ~ options', options)
  console.log(
    '🚀 ~ file: CartItemValued.jsx ~ line 15 ~ productData',
    productData
  )
  return (
    <div className="CartItemValued">
      <div className="Avatar">
        <Img src={productData.avatar.publicUrl} />
      </div>
      <div className="Content">
        <div className="CartItem-Head">
          <GenderIcon type={assignment} />
          <p className="Body-Medium-1">{productData.frontTitle}</p>
          {productData.params.map((p) => {
            const values = []
            if (p.param) {
              values.push(optionsValues[`main-${p.param}`])
            }
            return !isEmpty(values) ? (
              <p className="CartItem-Param">{values.join('/')}</p>
            ) : null
          })}
        </div>
        <div className="CartItemValued-Content">
          {options.map((o) => {
            const optionData = find(productData.options, ['id', o])

            return (
              <p className="Body-Regular-2 Content-Value">
                <span>{optionData.title}</span>
                <span className="Value">
                  {optionData.param ? (
                    `${optionsValues[`${o}-${optionData.param}`]}`
                  ) : (
                    <Icon name="info-circle" />
                  )}
                </span>
              </p>
            )
          })}
          {/*
          <p className="Body-Regular-2 Content-Value">
            <span>Шолом</span>
            <span className="Value">
              <Icon name="info-circle" />
            </span>
          </p> */}
        </div>
      </div>
      <div className="PriceLine">
        <span className="Body-Medium-2">За комплект:</span>
        <span className="Body-Medium-1">{productPrice} ₴</span>
      </div>
    </div>
  )
}

export default CartItem
