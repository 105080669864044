import { cloneDeep, find, isEmpty, orderBy } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../index'
import Icon from '../../../ui/Icon/Icon'
import Spinner from '../../../ui/Spinner/Spinner'
import getCollection from '../../../utils/db/getCollection'
import { CustomDdlistElement } from '../SearchInput/SearchInput'
import './MobileStepper.sass'
import Button from '../../../ui/Button/Button'
import getFormattedValue from '../DateChooser/functions/getFormattedValue'
import { createForm } from '../../../utils/forms/createForm'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import DatePicker from '../../../ui/DatePicker/DatePicker'
import { differenceInCalendarDays } from 'date-fns'
import FormRender from '../../../utils/forms/render/FormRender'
import { withRouter } from 'react-router-dom'
import useScrollLock from '../../../hooks/useScrollLock'

const MobileStepper = React.forwardRef(({ onClose, ...router }, ref) => {
  const [steps, setSteps] = useState([
    {
      id: 'sport',
      title: 'Оберіть вид спорту',
      component: SportChooser,
      isActive: true,
      nextStep: 'location',
      prevStep: false,
    },
    {
      id: 'location',
      title: 'Де заберете?',
      component: LocationChooser,
      isActive: false,
      nextStep: 'dates',
      prevStep: 'sport',
    },
    {
      id: 'dates',
      title: 'Коли будете там?',
      component: DatesChooser,
      isActive: false,
      prevStep: 'location',
    },
  ])

  const activeStep = find(steps, ['isActive', true])

  const onPrevClicked = (id) => {
    const clone = cloneDeep(steps)
    clone.forEach((s) => (s.isActive = s.id === id))
    setSteps(clone)
  }

  const onNextClicked = (id) => {
    const clone = cloneDeep(steps)
    clone.forEach((s) => (s.isActive = s.id === id))
    setSteps(clone)
  }

  useScrollLock()

  return (
    <div ref={ref} className="MobileStepper">
      <div className="MobileStepper-Header">
        {activeStep.prevStep ? (
          <Icon
            name="arrow-left"
            onClick={() => onPrevClicked(activeStep.prevStep)}
          />
        ) : null}
        <Icon name="times" onClick={onClose} />
      </div>
      <h3 className="Title">{activeStep.title}</h3>
      <div className="MobileStepper-Body">
        {<activeStep.component onNext={onNextClicked} router={router} />}
      </div>
    </div>
  )
})

function SportChooser({ onNext }) {
  const [categories, setCategories] = useState(null)
  const { setCategory } = useContext(AuthContext)

  useEffect(() => {
    const activeCategory = find(categories, ['isActive', true])
    if (activeCategory) {
      setCategory(activeCategory)
    } else {
      setCategory({ id: null, title: null })
    }
  }, [categories])

  useEffect(() => {
    getCollection({
      docIdName: 'id',
      path: 'config/products/categories',
      whereQueries: [{ fieldPath: 'parentCategory', op: '==', value: 'root' }],
    }).then((result) => {
      setCategories(
        orderBy(result, ['position'], ['asc']).map((c) => {
          c.isActive = localStorage.getItem('category') === c.id
          return c
        })
      )
    })
  }, [])

  const onCategoryClicked = (categoryId) => {
    const categoriesClone = cloneDeep(categories)
    setCategories(
      categoriesClone.map((c) => {
        c.isActive = categoryId === c.id
        localStorage.setItem('category', categoryId)
        return c
      })
    )
  }

  return (
    <div className="SportChooser">
      <div className="SportChooser-List">
        {!isEmpty(categories) ? (
          categories.map((c) => (
            <CustomDdlistElement
              {...c}
              onClick={() => onCategoryClicked(c.id)}
            />
          ))
        ) : (
          <Spinner type="popup" />
        )}
      </div>
      <div className="Buttons">
        <Button
          title="Далі"
          disabled={!find(categories, ['isActive', true])}
          onClick={() => onNext('location')}
        />
      </div>
    </div>
  )
}

function LocationChooser({ onNext }) {
  const [locations, setLocations] = useState(null)
  const { setLocation } = useContext(AuthContext)

  useEffect(() => {
    getCollection({
      docIdName: 'id',
      path: 'cities',
    }).then((result) => {
      setLocations(
        orderBy(result, ['title'], ['asc']).map((l) => {
          l.isActive = localStorage.getItem('location') === l.id
          return l
        })
      )
    })
  }, [])

  useEffect(() => {
    const activeLocation = find(locations, ['isActive', true])
    if (activeLocation) {
      setLocation(activeLocation)
    } else {
      setLocation({
        title: null,
        id: null,
        lat: null,
        lng: null,
      })
    }
  }, [locations])

  const onLocationClicked = (locationId) => {
    const locationsClone = cloneDeep(locations)
    setLocations(
      locationsClone.map((l) => {
        l.isActive = locationId === l.id
        return l
      })
    )
    localStorage.setItem('location', locationId)
  }

  return (
    <div className="LocationChooser">
      <div className="LocationChooser-List">
        {!isEmpty(locations) ? (
          locations.map((l) => (
            <CustomDdlistElement
              icon="map-marker-alt"
              weight="regular"
              {...l}
              onClick={() => onLocationClicked(l.id)}
            />
          ))
        ) : (
          <Spinner type="popup" />
        )}
      </div>
      <div className="Buttons">
        <Button
          title="Далі"
          disabled={!find(locations, ['isActive', true])}
          onClick={() => onNext('dates')}
        />
      </div>
    </div>
  )
}

function DatesChooser({ router }) {
  const { dates, setDates } = useContext(AuthContext)
  const [selectedDate, setSelectedDate] = useState(dates)

  const [form, setForm] = useState(
    createForm({
      formPattern: {
        sameDay: {
          field: {
            fieldId: 'sameDay',
            fieldType: 'checkbox',
            label: 'Заберу після 15:00 (-1 день оренди)',
          },
          render: getFieldRenderObject(),
        },
      },
      formData: {
        sameDay: localStorage.getItem('sameDay'),
      },
    })
  )

  useEffect(() => {
    if (selectedDate.start && selectedDate.end) {
      setDates(selectedDate)
    } else {
      setDates({ start: null, end: null })
    }
  }, [selectedDate])

  useEffect(() => {
    if (form.sameDay.value) {
      localStorage.setItem('sameDay', true)
    } else {
      localStorage.removeItem('sameDay')
    }
  }, [form.sameDay.value])

  const onDateChange = ([start, end]) => {
    setSelectedDate({ start, end })
  }

  const onFlexibleClicked = () => {
    localStorage.removeItem('date')
    localStorage.removeItem('sameDay')
    setDates({ start: null, end: null })
    router.history.push('/catalog')
  }

  const onSearchClicked = () => {
    setDates(selectedDate)
    localStorage.setItem('date', JSON.stringify(selectedDate))
    router.history.push('/catalog')
  }

  return (
    <>
      <span className="Body-Regular-1">
        {getFormattedValue({
          start: dates.start,
          end: dates.end,
          sameDay: form.sameDay.value,
        })}
      </span>
      {differenceInCalendarDays(selectedDate.end, selectedDate.start) > 0 ? (
        <FormRender
          sections={[{ fields: ['sameDay'] }]}
          form={form}
          setForm={setForm}
        />
      ) : null}
      <div className="DateChooser">
        <DatePicker
          selected={selectedDate.start}
          startDate={selectedDate.start}
          endDate={selectedDate.end}
          selectsRange
          onChange={onDateChange}
          monthsShown={2}
          minDate={new Date()}
        />
        <div className="Buttons">
          <Button
            title="Гнучкі дати"
            theme="secondary"
            onClick={onFlexibleClicked}
          />
          <Button title="Пошук" icon="search" onClick={onSearchClicked} />
        </div>
      </div>
    </>
  )
}

export default withRouter(MobileStepper)
