import Glide from '@glidejs/glide'
import './Carousel.sass'
import React, { useState, useEffect } from 'react'
import Icon from '../../Icon/Icon'

const Carousel = ({ element = 'glide', options, children }) => {
  const [slider] = useState(new Glide(`.${element}`, options))
  useEffect(() => {
    slider.mount()

    // subscribe to an event
    slider.on('run.before', (event) => {
      // ... do something cool here
    })

    // cleanup when unmounting component
    return () => slider.destroy()
  }, [])

  useEffect(() => {
    if (options.peek) {
      slider.update({ peek: options.peek })
    }
  }, [options.peek])

  return (
    <div className={`Carousel ${element && element}`}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {children.map((slide, index) => (
            <li className={`${slide.props.className} glide__slide`}>{slide}</li>
          ))}
        </ul>
      </div>
      {options.useDots && (
        <div className="Carousel-DotsContainer" data-glide-el="controls[nav]">
          {children.map((c, i) => (
            <span className="Carousel-Dot" data-glide-dir={`=${i}`} />
          ))}
        </div>
      )}
      {options.useArrows && (
        <div data-glide-el="controls">
          <button className="Carousel-Arrow Arrow-Left" data-glide-dir="<">
            <Icon name="angle-left" />
          </button>
          <button className="Carousel-Arrow Arrow-Right" data-glide-dir=">">
            <Icon name="angle-right" />
          </button>
        </div>
      )}
    </div>
  )
}

export default Carousel
