import React from 'react'
import { functions } from '../../../../config/firebase'
import { renderEmail } from 'react-html-email'
import SignUpEmail from '../../../Emails/SignUpEmail'

const sendSignUpEmail = ({ to, password }) => {
  const func = functions.httpsCallable('emailSender')
  const messageHtml = renderEmail(
    <SignUpEmail login={to} password={password} />
  )

  return func({
    to,
    subject: 'Регистрация успешно завершена',
    html: messageHtml,
  })
}

export default sendSignUpEmail
