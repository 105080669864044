import React from 'react'
import './TabsController.sass'

function TabsController({ tabs = [], onClick = () => null }) {
  return (
    <div
      className="TabsController"
      style={{ gridTemplateColumns: `repeat(${tabs.length}, max-content)` }}
    >
      {tabs.map((t) => (
        <Tab {...t} onClick={() => onClick(t.id)} />
      ))}
    </div>
  )
}

function Tab({ title, counter, isActive, onClick }) {
  return (
    <div
      className={['Tab', ...(isActive ? ['Tab_theme_active'] : [''])].join(' ')}
      onClick={onClick}
    >
      <span className="Title Body-Medium-2">{title}</span>
      <span className={'Counter Body-Regular-2'}>{counter}</span>
    </div>
  )
}

export default TabsController
