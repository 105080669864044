import React from 'react'
import './TabGroup.sass'

function TabGroup({ tabs, onClick }) {
  return (
    <div className="TabGroup">
      {tabs.map((t) => (
        <Tab {...t} onClick={() => onClick(t.id)} />
      ))}
    </div>
  )
}

function Tab({ title, counter, isActive, onClick }) {
  return (
    <span
      className={[
        'Body-Medium-2 Tab',
        ...(isActive ? ['Tab_theme_active'] : []),
      ].join(' ')}
      onClick={onClick}
    >
      {title} {counter}
    </span>
  )
}

export default TabGroup
