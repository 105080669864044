import sendSms from '../../../../utils/sendSms'

const sendPasswordRecoverySms = ({ to, recoveryCode }) => {
  const message = `Мы получили запрос на восстановление вашего пароля. Воспользуйтесь ссылкой ниже или скопируйте и вставьте ее в свой браузер, чтобы установить новый пароль: https://asdnord.ru/password-recovery?recoveryCode=${recoveryCode}`

  return sendSms({
    phone: to,
    message,
  })
}

export default sendPasswordRecoverySms
