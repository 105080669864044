import React, { useContext, useEffect, useRef, useState } from 'react'
import SitePage from '../SitePage/SitePage'
import './Services.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import PriceTable from '../../components/Price/PriceTable'
import CartOnPage from '../../components/Cart/CartOnPage/CartOnPage'
import { AuthContext } from '../..'
import { cloneDeep, find, isEmpty, isNull, orderBy } from 'lodash'
import getCollection from '../../utils/db/getCollection'
import promiseAllValues from '../../utils/promiseAllValues'
import toggleSpoiler from '../../utils/toggleSpoiler'
import Icon from '../../ui/Icon/Icon'
import { withRouter } from 'react-router-dom'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import { createForm } from '../../utils/forms/createForm'
import getFieldRenderObject from '../../utils/forms/render/getFieldRenderObject'
import FormRender from '../../utils/forms/render/FormRender'
import Spinner from '../../ui/Spinner/Spinner'

function Services({ ...router }) {
  const { cities } = useContext(AuthContext)
  const defaultCity = find(cities, ['isDefault', true])
  const [price, setPrice] = useState(null)
  const openPriceId = router.match.params.id
  const myRef = useRef(null)

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
      }),
      services: getCollection({
        path: 'settings/price/services',
        docIdName: 'serviceId',
      }),
    }).then((result) => {
      const catsByCity = result.categories.filter(
        (c) => c.cityId === defaultCity.cityId && c.isDeleted !== true
      )
      const servicesByCity = result.services.filter(
        (s) =>
          find(catsByCity, ['categoryId', s.categoryId]) && s.isDeleted !== true
      )

      setPrice(
        orderBy(
          catsByCity.map((category) => ({
            ...category,
            services: servicesByCity.filter(
              (s) => s.categoryId === category.categoryId
            ),
          })),
          ['pos'],
          ['asc']
        )
      )
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(price) && !isEmpty(myRef)) {
      const el = document.getElementById(openPriceId)
      if (el) scrollWithOffset(el, -120)
    }
  }, [price, myRef])

  return (
    <SitePage>
      <div className="Services">
        <div className="Services-Container DefaultContainer">
          <SectionTitle title="Услуги и цены" />
          <div className="Services-Content">
            <ServicesPrice
              price={price}
              myRef={myRef}
              openPriceId={openPriceId}
              defaultCity={defaultCity}
            />
            <CartOnPage />
          </div>
        </div>
      </div>
    </SitePage>
  )
}

export const ServicesPrice = ({
  price,
  myRef = null,
  openPriceId = null,
  defaultCity = {},
  hideServicesOnCheckout = false,
}) => {
  const [form, setForm] = useState(
    createForm({ formPattern: new SearchForm() })
  )

  const [filteredPrice, setFilteredPrice] = useState([])
  console.log('🚀 ~ filteredPrice:', filteredPrice)

  useEffect(() => {
    if (!isNull(price) && form.text.value.length >= 3) {
      const newPriceArray = []
      const clone = cloneDeep(price)

      clone.forEach((p) => {
        const services = p.services.filter((s) => {
          return s.title.toLowerCase().includes(form.text.value.toLowerCase())
        })

        if (!isEmpty(services)) {
          newPriceArray.push({ ...p, services })
        }
      })
      setFilteredPrice(newPriceArray)
    }
  }, [form.text.value])

  return (
    <div className="PriceContainer">
      <div className="SearchBar">
        <FormRender
          form={form}
          setForm={setForm}
          sections={[{ fields: ['text'] }]}
          // errors={showErrors}
        />
      </div>
      {!isNull(price) ? (
        form.text.value.length >= 3 ? (
          !isEmpty(filteredPrice) ? (
            filteredPrice.map((p, i) => (
              <div className={`Spoiler Spoiler_status_expanded`}>
                <div className="CategoryHeader">
                  <Icon name="angle-right" />
                  <h4 className="Title">{p.title}</h4>
                </div>
                <div className="CategoryBody">
                  {p.fixedCheckout && (
                    <div className="NotificationText">
                      <Icon name="info-circle" />
                      Для уточнения стоимости данных услуг требуется
                      консультация специалиста
                    </div>
                  )}
                  <PriceTable category={p} price={p.services} emptyAddButton />
                </div>
              </div>
            ))
          ) : (
            <p className="SearchResult">
              Ничего не найдено. Попробуйте изменить поисковой запрос
            </p>
          )
        ) : (
          price.map((p, i) => (
            <div
              id={p.categoryId}
              ref={openPriceId === p.categoryId ? myRef : null}
              className={`Spoiler ${
                ((openPriceId && p.categoryId === openPriceId) ||
                  (!openPriceId &&
                    defaultCity.defaultPriceCatId === p.categoryId)) &&
                'Spoiler_status_expanded'
              }`}
            >
              <div className="CategoryHeader" onClick={toggleSpoiler}>
                <Icon name="angle-right" />
                <h4 className="Title">{p.title}</h4>
              </div>
              <div className="CategoryBody">
                {p.fixedCheckout && (
                  <div className="NotificationText">
                    <Icon name="info-circle" />
                    Для уточнения стоимости данных услуг требуется консультация
                    специалиста
                  </div>
                )}
                <PriceTable category={p} price={p.services} emptyAddButton />
              </div>
            </div>
          ))
        )
      ) : (
        <Spinner type="module" />
      )}
    </div>
  )
}

class SearchForm {
  constructor() {
    this.text = {
      field: {
        fieldId: 'text',
        fieldType: 'input',
        inputType: 'text',
        label: 'Поиск по услугам',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default withRouter(Services)
