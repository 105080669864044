import { firebase } from '../../../../config/firebase'
import setDoc from '../../../../utils/db/setDoc'
import sendSignUpEmail from './sendSignUpEmail'

const signUpUser = (values) => {
  const { email, password } = values

  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((result) =>
      Promise.all([
        setDoc({
          path: 'users',
          docId: result.user.uid,
          data: values,
        }),
        sendSignUpEmail({ to: email, password }),
      ])
    )
}

export default signUpUser
