import React from 'react'
import illustration from './assets/recoverySent.svg'
import Button from '../../../../ui/Button/Button'
import './PasswordRecoveryMessage.sass'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../../ui/NotificationBlock/NotificationBlock'
import sendRecoveryLink from '../functions/sendRecoveryLink'

function PasswordRecoveryMessage({ to }) {
  const [nBlockSettings, setNBlockSettings] = useNotificationBlock()

  const onResendClicked = () => {
    setNBlockSettings({
      show: true,
      theme: 'success',
      title: 'Успешно отправлено',
      content: 'Еще одна ссылка на восстановление пароля успешно отправлена.',
    })
    setTimeout(() => setNBlockSettings({ show: false }), 3000)
    sendRecoveryLink({ to }).catch((e) => {
      console.log(
        '🚀 ~ file: PasswordRecoveryMessage.jsx ~ line 22 ~ onResendClicked ~ e',
        e
      )
    })
  }

  return to ? (
    <div className="PasswordRecoveryMessage-Form">
      <img src={illustration} alt="Message sent successfully" />
      {nBlockSettings.show && <NotificationBlock {...nBlockSettings} />}
      <h3>
        Мы отправили ссылку для восстановления{' '}
        {to[0] === '+' ? 'на ваш номер' : 'пароля'}
      </h3>
      <p className="Text Body-Regular-2">
        Пожалуйста, проверьте Ваш{' '}
        {to[0] === '+' ? 'входящие сообщения' : 'e-mail'}
      </p>
      <p className="Text Caption-Medium Resend-Text">
        Не получили ссылку?{' '}
        <Button
          title="Отправить еще раз"
          size={24}
          theme="internal-link"
          onClick={onResendClicked}
        />
      </p>
    </div>
  ) : null
}

export default PasswordRecoveryMessage
