import React from 'react'
import Bar from 'react-password-strength-bar'
import './PasswordStrengthBar.sass'

function PasswordStrengthBar({ password }) {
  const onChangeScore = (score, feedback) => {
    console.log(
      '🚀 ~ file: PasswordStrengthBar.jsx ~ line 7 ~ onChangeScore ~ feedback',
      feedback
    )
    console.log(
      '🚀 ~ file: PasswordStrengthBar.jsx ~ line 7 ~ onChangeScore ~ score',
      score
    )
  }
  return (
    <div className="PasswordStrengthBar">
      <Bar
        className="Bar"
        password={password}
        shortScoreWord="Слишком короткий"
        barColors={['#E2E6EB', '#DB3B21', '#FC9403', '#1F78D1', '#1AAA55']}
        scoreWords={[
          'Очень слабый',
          'Очень слабый',
          'Ненадежный',
          'Надежный',
          'Безопасный',
        ]}
        scoreWordClassName
        minLength={6}
        onChangeScore={onChangeScore}
      />
      <ul className="Text Caption-Regular">
        <li>Минимум 6 символов;</li>
        <li>
          Содержит алфавитные символы верхнего и нижнего регистра(A-Я, а-я);
        </li>
        <li>Содержит хотябы одну цифру (0-9);</li>
        <li>Содержит хотябы один спец. символ (~@#$%^&*()_-+=).</li>
      </ul>
    </div>
  )
}

export default PasswordStrengthBar
