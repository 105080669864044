import React from 'react'
import './Home.sass'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import heroBg from './headerNew.jpg'
import HomeSearch from '../../components/HomeSearch/HomeSearch'
import getCollection from '../../utils/db/getCollection'
import SitePage from '../SitePage/SitePage'
import About from '../../components/About/About'
import Button from '../../ui/Button/Button'
import TotalNav from '../../components/TotalNav/TotalNav'
import News from '../../components/News/News'
import Specialization from '../../components/Specialization/Specialization'
import HomeSpecialization from '../../components/HomeSpecialization/HomeSpecialization'
import DoctorsSlider from '../../components/DoctorsSlider/DoctorsSlider'

// import getCollection from '../../utils/db/getCollection'
// import { createForm } from '../../utils/forms/createForm'
// import UserObject from '../../components/Auth/SignUp/functions/userObject'
// import getFormValues from '../../utils/forms/getFormValues'
// import { omit } from 'lodash'
// import setDoc from '../../utils/db/setDoc'

function Home() {
  //Update old users script
  // const test = () => {
  //   getCollection({
  //     path: 'users',
  //     docIdName: 'userId',
  //   }).then((result) => {
  //     const newUsers = result
  //       .filter((u) => !u.version)
  //       .map((u) => {
  //         const form = createForm({
  //           formData: omit(u, ['avatar']),
  //           formPattern: new UserObject(),
  //         })
  //         return { userId: u.userId, ...getFormValues({ form }) }
  //       })

  //     Promise.all(
  //       newUsers.map((u) =>
  //         setDoc({ path: 'users', docId: u.userId, data: omit(u, ['userId']) })
  //       )
  //     )
  //       .then((result) => {
  //         console.log(
  //           '🚀 ~ file: Home.jsx ~ line 32 ~ Promise.all ~ result',
  //           result
  //         )
  //       })
  //       .catch((e) => {
  //         console.log('🚀 ~ file: Home.jsx ~ line 35 ~ Promise.all ~ e', e)
  //       })
  //   })
  // }

  // const test = () => {
  //   getCollection({
  //     path: 'users',
  //     docIdName: 'userId',
  //     whereQueries: [{ fieldPath: 'phone', op: '==', value: 380631702147 }],
  //   }).then((result) => {
  //     console.log('🚀 ~ file: Home.jsx ~ line 56 ~ test ~ result', result)
  //   })
  // }

  return (
    <SitePage>
      <div className="Home">
        <Header
          className={'Home-Header'}
          backgroundSrc={heroBg}
          title={
            <h1 className="Offer-Title">
              <span>Все виды</span>
              <br />
              медицинских услуг
            </h1>
          }
          description={
            <p className="Offer-Description">
              Запишитесь на прием к врачу Online,{' '}
              <span>в удобное для Вас время!</span>
            </p>
          }
          buttons={
            <>
              <Button
                theme="primary-white"
                title="Записаться на прием"
                type="navlink"
                icon="arrow-right"
                iconPosition="right"
                path="/zapis-na-priem"
              />
            </>
          }
        />
        <TotalNav />
        <HomeSpecialization />
        <DoctorsSlider />
        <About />
        <News />
      </div>
    </SitePage>
  )
}

export default Home
