import { cloneDeep, isArray, find, isEmpty, isObject } from 'lodash'

const isFormValid = ({
  form,
  checkFieldsEquality,
  checkFields = [],
  omitFields = [],
}) => {
  const formClone = cloneDeep(form)
  let formToCheck = formClone

  if (checkFieldsEquality) {
    if (!isArray(form)) {
      const fieldsValuesToCheck = []
      checkFieldsEquality.fields.forEach((f) =>
        fieldsValuesToCheck.push(form[f].value)
      )
      if (!fieldsValuesToCheck.every((v) => v === fieldsValuesToCheck[0])) {
        formToCheck[checkFieldsEquality.setError.field].valid = false
        formToCheck[checkFieldsEquality.setError.field].error =
          checkFieldsEquality.setError.error
        checkFieldsEquality.setError.updateForm(formToCheck)
        return false
      }
      formToCheck[checkFieldsEquality.setError.field].valid = true
      formToCheck[checkFieldsEquality.setError.field].error = ''
      checkFieldsEquality.setError.updateForm(formToCheck)
    }
  }

  if (!isArray(formClone)) {
    formToCheck = Object.values(formClone)
  }

  let requiredFields = formToCheck.filter(
    (field) =>
      (field.validation &&
        field.render.byDefault &&
        field.validation.required &&
        true) ||
      (field.required && field.render.byDefault && true) ||
      (!field.render.byDefault &&
        field.required &&
        find(formToCheck, ['fieldId', field.render.ifFieldId]).value ===
          field.render.value) ||
      (!field.render.byDefault &&
        field.validation &&
        field.validation.required &&
        find(formToCheck, ['fieldId', field.render.ifFieldId]).value ===
          field.render.value)
  )

  if (!isEmpty(checkFields)) {
    requiredFields = requiredFields.filter((f) =>
      checkFields.includes(f.fieldId)
    )
  }
  if (!isEmpty(omitFields)) {
    requiredFields = requiredFields.filter(
      (f) => !omitFields.includes(f.fieldId)
    )
  }

  const formIsValid = [true]

  requiredFields.forEach((field) => {
    if (
      field.required === true &&
      field.render.isSystem &&
      ((isArray(field.values) && isEmpty(field.values)) ||
        (isObject(field.values) && isEmpty(field.values)) ||
        (isObject(field.values) &&
          field.requiredProp &&
          !field.values[field.requiredProp]) ||
        !field.values)
    )
      return formIsValid.push(false)
    if (
      field.required === true &&
      field.isFieldset &&
      fieldsetHasEmptyRequiredField(field.values)
    )
      return formIsValid.push(false)
    if (field.valid === false) return formIsValid.push(false)
  })

  return formIsValid.every((el) => el && true)
}

const fieldsetHasEmptyRequiredField = (values) => {
  const invalidFields = values.map((fs) => !isEmpty(fs.filter((f) => !f.valid)))
  return invalidFields.some((v) => v === true)
}

export default isFormValid
