import React from 'react'
import { Item, Span } from 'react-html-email'

function EmailSignature() {
  return (
    <>
      <Item
        style={{
          paddingTop: '24px',
        }}
      >
        <Span
          style={{
            display: 'block',
            width: '100%',
            height: '1px',
            backgroundColor: '#E2E6EB',
          }}
        />
      </Item>
      <Item
        align="center"
        style={{
          paddingTop: '12px',
        }}
      >
        <Span
          style={{
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '18px',
            color: '#484F56',
          }}
        >
          Если у Вас возникли вопросы, пожалуйста, напишите нам на{' '}
          <a href="mailto: asdnord@yandex.ru" style={{ color: '#09A8CA' }}>
            asdnord@yandex.ru
          </a>{' '}
          или позвоните по телефонам: г. Мурманск{' '}
          <a href="tel:+78152584458" style={{ color: '#09A8CA' }}>
            +7 (8152) 58-44-58
          </a>
          , г. Заполярный{' '}
          <a href="tel:+79537510211" style={{ color: '#09A8CA' }}>
            +7 (953) 751-02-11
          </a>{' '}
        </Span>
      </Item>
    </>
  )
}

export default EmailSignature
