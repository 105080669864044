import getFieldRenderObject from '../../../../utils/forms/render/getFieldRenderObject'

export default class UserObject {
  constructor({ disabledFields = [] } = {}) {
    this.avatar = {
      field: {
        fieldId: 'avatar',
        value: Math.floor(Math.random() * 3) + 1,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: 'Имя',
        placeholder: 'Имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.lastName = {
      field: {
        fieldId: 'lastName',
        fieldType: 'input',
        inputType: 'text',
        label: 'Фамилия',
        placeholder: 'Фамилия',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'input',
        inputType: 'phone',
        mask: 'phone',
        label: 'Телефон',
        placeholder: '+7(ХХХ) XXX-XX-XX',
        required: true,
      },
      render: getFieldRenderObject({
        isChangableAfterSave: !disabledFields.includes('phone'),
      }),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        placeholder: 'sample@gmail.com',

        required: true,
      },
      render: getFieldRenderObject({
        isChangableAfterSave: !disabledFields.includes('email'),
      }),
    }
    this.password = {
      field: {
        fieldId: 'password',
        fieldType: 'input',
        inputType: 'password',
        label: 'Пароль',
        placeholder: 'Создайте ваш пароль',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phoneVerified = {
      field: {
        fieldId: 'phoneVerified',
        value: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.phoneVerificationCode = {
      field: {
        fieldId: 'phoneVerificationCode',
        value: null,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.signUpType = {
      field: {
        fieldId: 'signUpType',
        value: 'manual',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.version = {
      field: {
        fieldId: 'version',
        value: 1.0,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.gender = {
      field: {
        fieldId: 'gender',
        value: null,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.bDate = {
      field: {
        fieldId: 'bDate',
        fieldType: 'input',
        inputType: 'text',
        mask: 'date',
        label: 'Дата рождения',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.city = {
      field: {
        fieldId: 'city',
        fieldType: 'input',
        inputType: 'text',
        label: 'Город',
        placeholder: 'Не указан',
      },
      render: getFieldRenderObject(),
    }
  }
}
