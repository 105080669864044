import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../ui/Button/Button'
import Icon from '../../../ui/Icon/Icon'
import './CartOnPage.sass'
import { AuthContext } from '../../..'
import getCollection from '../../../utils/db/getCollection'
import { find, isNull, toNumber } from 'lodash'
import updateProducts from '../functions/updateProducts'
import updateDoc from '../../../utils/db/updateDoc'
import { withRouter } from 'react-router-dom'

function CartOnPage({ hideCheckoutButton = false, ...router }) {
  const { cart } = useContext(AuthContext)
  const [services, setServices] = useState(null)
  const [amount, setAmount] = useState(0)
  const { cities, setCities } = useContext(AuthContext)
  const defaultCity = find(cities, ['isDefault', true])

  useEffect(() => {
    getCollection({
      path: 'settings/price/services',
      docIdName: 'serviceId',
    }).then((result) => setServices(result))
  }, [])

  const removeService = (serviceId) => {
    updateProducts({
      cartId: cart.id,
      products: cart.products.filter((p) => p !== serviceId),
    })
  }

  useEffect(() => {
    if (!isNull(services)) {
      let count = 0
      cart.products.forEach((p) => {
        const service = find(services, ['serviceId', p])
        count += toNumber(service.price)
      })
      setAmount(count)
    }
  }, [services, cart.products])

  const onCartCheckout = () => {
    const specialization = []
    cart.products.forEach((p) => {
      const service = find(services, ['serviceId', p])
      if (service) {
        specialization.push(service.categoryId)
      }
    })
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: {
        clinicId: defaultCity.cityId,
        specialization,
        curStep: 'doctor',
      },
    }).then(() => router.history.push('/zapis-na-priem'))
  }

  return (
    <div className="CartOnPage">
      <div className="CartOnPage-Header">
        <Icon name="briefcase-medical" />
        <span className="Title">Корзина</span>
      </div>
      <div className="CartOnPage-Body">
        {!isNull(services) &&
          cart.products.map((p) => {
            const service = find(services, ['serviceId', p])

            return (
              <CartItem
                key={p}
                title={service.title}
                price={service.price}
                removeService={() => removeService(p)}
              />
            )
          })}

        <div className="CartOnPage-Total">
          <span className="Total">Итого:</span>{' '}
          <span className="Price">{amount} ₽</span>
        </div>
      </div>
      {!hideCheckoutButton && (
        <Button
          theme="primary"
          title="Записаться на прием"
          type="button"
          icon="arrow-right"
          iconPosition="right"
          onClick={() => onCartCheckout()}
          className="CartOnPage-Button"
        />
      )}
    </div>
  )
}

function CartItem({ title, price, removeService }) {
  return (
    <div className="CartItem">
      <p className="ItemName">{title}</p>
      <p className="Price">{toNumber(price)} ₽</p>
      <div className="TimesButton" onClick={removeService}>
        <Icon name="times" />
      </div>
    </div>
  )
}

export default withRouter(CartOnPage)
