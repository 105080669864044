import React from 'react'
import { functions } from '../../../../config/firebase'
import { renderEmail } from 'react-html-email'
import VerificationEmail from '../../../Emails/VerificationEmail'

const sendEmailVerificationCode = ({ to, code }) => {
  const func = functions.httpsCallable('emailSender')
  const messageHtml = renderEmail(<VerificationEmail code={code} />)

  return func({
    to,
    subject: 'Код для подтверждения E-mail',
    html: messageHtml,
  })
}

export default sendEmailVerificationCode
