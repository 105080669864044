import React, { useContext } from 'react'
import Button from '../../ui/Button/Button'
import './PriceTable.sass'
import { cloneDeep, find, orderBy, toNumber } from 'lodash'
import { AuthContext } from '../..'
import updateProducts from '../Cart/functions/updateProducts'

function PriceTable({ category, price, emptyAddButton }) {
  const { cart } = useContext(AuthContext)

  const onProductPick = (productId) => {
    let products = cloneDeep(cart.products)
    const thisProduct = products.includes(productId)

    if (!thisProduct) {
      products.push(productId)
    } else {
      products = products.filter((p) => p !== productId)
    }
    updateProducts({ cartId: cart.id, products })
  }

  return (
    <table className="PriceTable">
      <thead>
        <tr>
          <th className="Body-Bold-2">Наименование</th>
          <th style={{ width: '120px' }} className="Body-Bold-2">
            Стоимость
          </th>
          <th style={{ width: '24px' }}></th>
        </tr>
      </thead>
      <tbody>
        {orderBy(price, ['pos'], ['asc']).map((p) => (
          <tr>
            <td className="Body-Regular-2">{p.title}</td>
            <td className="Body-Regular-2">{toNumber(p.price)} руб</td>
            <td className="PriceTable-PriceButton">
              {category.fixedCheckout ? (
                <Button
                  icon={'comment-alt-lines'}
                  iconWeight="solid"
                  size={24}
                  onClick={() => window.jivo_api.open()}
                  className={'ProductChat'}
                  title="Уточнить"
                />
              ) : (
                <Button
                  icon={cart.products.includes(p.serviceId) ? 'minus' : 'plus'}
                  size={24}
                  onClick={() => onProductPick(p.serviceId)}
                  className={
                    cart.products.includes(p.serviceId)
                      ? 'ProductInCart'
                      : 'ProductNotInCart'
                  }
                />
              )}
            </td>
          </tr>
        ))}
        {/* <tr>
          <td className="Body-Regular-2">Название услуги</td>
          <td className="Body-Regular-2">1000 руб</td>
          <td>
            <Button icon="plus" size={24} />
          </td>
        </tr> */}
      </tbody>
    </table>
  )
}

export default PriceTable
