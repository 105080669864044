import React from 'react'
import { functions } from '../../../../config/firebase'
import { renderEmail } from 'react-html-email'
import CheckoutEmail from '../../../../components/Emails/CheckoutEmail'
import promiseAllValues from '../../../../utils/promiseAllValues'
import getDoc from '../../../../utils/db/getDoc'
import getCollection from '../../../../utils/db/getCollection'
import { formatDateAsDayMMYearHHMM } from '../../../../utils/date/dateFormat'
import { find } from 'lodash'

const sendCheckoutEmail = ({ eventId, email }) => {
  const func = functions.httpsCallable('emailSender')

  return promiseAllValues({
    event: getDoc({ path: 'schedule', docId: eventId, docIdName: 'eventId' }),
    categories: getCollection({
      path: 'settings/price/categories',
      docIdName: 'categoryId',
    }),
  }).then((data) => {
    const date = formatDateAsDayMMYearHHMM(data.event.dateStart.seconds * 1000)
    const category = find(data.categories, [
      'categoryId',
      data.event.specialization,
    ])

    const services = category ? category.title : null

    const messageHtml = renderEmail(
      <CheckoutEmail date={date} services={services} />
    )

    return func({
      to: email,
      subject: 'Запись на прием',
      html: messageHtml,
    })
  })
}

export default sendCheckoutEmail
