import React, { useState, useContext, useEffect } from 'react'
import './PickupPoints.sass'
import Icon from '../../../ui/Icon/Icon'
import Button from '../../../ui/Button/Button'
import PointTile from './PointTile/PointTile'
import LocationsMap from './LocationsMap/LocationsMap'
import PickupPointsNextStep from './PickupPointsNextStep/PickupPointsNextStep'
import getFormattedValue from '../../../components/HomeSearch/DateChooser/functions/getFormattedValue'
import { AuthContext } from '../../../index'
import {
  isNull,
  isEmpty,
  find,
  intersectionWith,
  intersection,
  cloneDeep,
  sum,
} from 'lodash'
import getCollection from '../../../utils/db/getCollection'
import promiseAllValues from '../../../utils/promiseAllValues'
import Spinner from '../../../ui/Spinner/Spinner'
import updateDoc from '../../../utils/db/updateDoc'
import DatePickerPopUp from '../DatePickerPopUp/DatePickerPopUp'
import defineProductPointPrice from './defineProductPointPrice'
import Media from 'react-media'
import PickupPointMobile from './PickupPointMobile/PickupPointMobile'

function PickupPoints() {
  const { dates, cart, location } = useContext(AuthContext)
  const [data, setData] = useState(null)
  const [points, setPoints] = useState(null)
  const [sameDay, setSameDay] = useState(localStorage.getItem('sameDay'))
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [mapSettings, setMapSettings] = useState(null)

  useEffect(() => {
    if (location.id) {
      promiseAllValues({
        spots: getCollection({ path: 'shops', docIdName: 'shopId' }).then(
          (shops) => {
            const spots = []
            shops.forEach((shop) => {
              shop.spots.forEach((spot) => {
                spots.push({
                  ...spot,
                  shopId: shop.shopId,
                  shopName: shop.title,
                  shopDescription: shop.description,
                })
              })
            })
            return spots.filter((s) => s.spotCity === location.id)
          }
        ),
        products: getCollection({ path: 'products', docIdName: 'productId' }),
      }).then((result) => setData(result))
    }
  }, [location])

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(cart.products)) {
      const spotIds = []
      cart.products.forEach((p) => {
        const productData = find(data.products, ['productId', p.productId])
        spotIds.push(Object.keys(productData.price))
      })

      const pointsIds = spotIds.reduce((p, c) => p.filter((e) => c.includes(e)))

      setPoints(
        data.spots
          .filter((spot) => pointsIds.includes(spot.spotId))
          .map((point) => {
            if (cart.pickupSpot === point.spotId) {
              point.isActive = true
              setMapSettings({
                center: { lat: +point.lat, lng: +point.lng },
                zoom: 16,
              })
            } else {
              point.isActive = false
            }
            point.coords = {
              lat: point.lat,
              lng: point.lng,
            }
            const pricesForProducts = defineProductPointPrice({
              pointId: point.spotId,
              products: data.products,
              cartProducts: cart.products,
              dates,
              sameDay,
            })

            point.price = sum(Object.values(pricesForProducts))
            point.pricesForProducts = pricesForProducts

            if (point.isActive) {
              updateCartPrice(point)
              console.log('test')
            }

            return point
          })
      )
    }
  }, [data, cart.products, dates, sameDay])

  const onPickupClicked = (pointId) => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: {
        pickupShop: find(points, ['spotId', pointId]).shopId,
        pickupSpot: pointId,
      },
    }).then(() => {
      const pointsClone = cloneDeep(points)
      setPoints(
        pointsClone.map((p) => {
          if (p.spotId === pointId) {
            p.isActive = true
            setMapSettings({ center: { lat: +p.lat, lng: +p.lng }, zoom: 16 })
          } else {
            p.isActive = false
          }
          return p
        })
      )
      updateCartPrice(find(pointsClone, ['isActive', true]))
    })
  }

  const onPickupDrop = () => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { pickupShop: null, pickupSpot: null },
    }).then(() => {
      const pointsClone = cloneDeep(points)
      setPoints(
        pointsClone.map((p) => {
          p.isActive = false
          return p
        })
      )
      updateCartPrice()
    })
  }

  const activePoint = points ? find(points, ['isActive', true]) : null

  useEffect(() => {
    const aPoint = find(points, ['isActive', true])
    updateCartPrice(aPoint)
  }, [dates, sameDay])

  const updateCartPrice = (aPoint) => {
    if (cart.id) {
      if (aPoint) {
        updateDoc({
          path: 'carts',
          docId: cart.id,
          data: {
            price: aPoint.price,
            pricesForProducts: aPoint.pricesForProducts,
            dates,
            sameDay,
          },
        })
      } else {
        updateDoc({
          path: 'carts',
          docId: cart.id,
          data: { price: 0, pricesForProducts: {}, dates: {}, sameDay },
        })
      }
    }
  }

  return (
    <div className="Site-Content PickupPoints">
      {showDatePicker && (
        <DatePickerPopUp
          title="Термін прокату"
          onClose={() => setShowDatePicker(false)}
          onSameDayClicked={setSameDay}
        />
      )}
      <div className="Info">
        <Button
          theme="internal-link"
          title="Повернутися"
          icon="angle-left"
          iconPosition="left"
          iconWeight="regular"
          type="navlink"
          path="/catalog/totals"
          size={32}
        />
        <InfoBlock
          title="Місто або курорт"
          content={
            <p className="TextWithButton Body-Regular-2">{location.title}</p>
          }
        />
        <InfoBlock
          title="Термін прокату"
          content={
            <>
              <p className="TextWithButton Body-Regular-2">
                {getFormattedValue({
                  start: dates.start,
                  end: dates.end,
                  sameDay,
                })}{' '}
                <Icon name="edit" onClick={() => setShowDatePicker(true)} />
              </p>
              {sameDay && (
                <p className="Caption-Regular">
                  Заберу після 15:00 (-1 день оренди)
                </p>
              )}
            </>
          }
        />
      </div>
      <div className="PickupPoints-Content">
        {isNull(points) ? (
          <div className="Spinner-Container">
            <Spinner type="button" theme="gray" />
          </div>
        ) : (
          <>
            <div className="PointsPanel">
              {points.map((p) => (
                <PointTile
                  {...p}
                  location={location}
                  onClick={onPickupClicked}
                />
              ))}
            </div>
            <LocationsMap
              defaultCenter={{ lat: +location.lat, lng: +location.lng }}
              {...mapSettings}
              points={points}
            />
          </>
        )}
      </div>
      {cart.pickupSpot && !isEmpty(points) && activePoint && (
        <>
          <Media
            query="(max-width: 1366px)"
            render={() => (
              <PickupPointMobile
                point={activePoint}
                onPickupDrop={onPickupDrop}
              />
            )}
          />
          <PickupPointsNextStep
            title={activePoint.title}
            address={`${location.title}, ${activePoint.spotAddress}`}
            onPickupDrop={onPickupDrop}
          />
        </>
      )}
    </div>
  )
}

export function InfoBlock({ title, content }) {
  return (
    <div className="InfoBlock">
      <p className="Body-Bold-2">{title}</p>
      {content}
    </div>
  )
}

export default PickupPoints
