import React from 'react'
import './ItemsTableHead.sass'

function ItemsTableHead() {
  return (
    <div className="ItemsTableHead">
      <span className="Caption-Medium">Спорядження</span>
      <span className="Caption-Medium">Ціна</span>
    </div>
  )
}

export default ItemsTableHead
