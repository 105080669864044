import React, { createContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles/main.sass'
// eslint-disable-next-line
import FontAwesomeLibrary from './config/fontawesome'
import { useEffect } from 'react'
import promiseAllValues from './utils/promiseAllValues'
import getCollection from './utils/db/getCollection'
import { defineDefaultCity } from './components/CityChooser/CityChooser'
import { db, firebase } from './config/firebase'
import getDoc from './utils/db/getDoc'
import { isEmpty } from 'lodash'
import setDoc from './utils/db/setDoc'
import addDoc from './utils/db/addDoc'
export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [cities, setCities] = useState(null)
  const [showContactUs, setShowContactUs] = useState({
    show: false,
    title: '',
    emailSubject: '',
  })

  const [user, setUser] = useState(null)
  const [cart, setCart] = useState({ id: null, products: [] })
  const [showAuth, setShowAuth] = useState(false)

  useEffect(() => {
    let dbUserListener = () => null
    let dbCartListener = () => null
    const userStateListener = firebase.auth().onAuthStateChanged((result) => {
      if (result && result.uid) {
        dbCartListener()
        dbUserListener = db
          .collection('users')
          .doc(result.uid)
          .onSnapshot((snap) => {
            const user = {
              uid: snap.id,
              ...snap.data(),
            }
            setUser(user)
          })
        if (localStorage.getItem('cart')) {
          getDoc({ path: 'carts', docId: localStorage.getItem('cart') }).then(
            (gCartData) => {
              if (!isEmpty(gCartData)) {
                setDoc({
                  path: 'carts',
                  docId: result.uid,
                  data: gCartData,
                }).then(() =>
                  db
                    .collection('carts')
                    .doc(localStorage.getItem('cart'))
                    .delete()
                    .then(() => localStorage.removeItem('cart'))
                )
              }
            }
          )
        }
        dbCartListener = db
          .collection('carts')
          .doc(result.uid)
          .onSnapshot((snap) => {
            const uCart = {
              id: snap.id || null,
              // products: [],
              ...snap.data(),
            }
            setCart(uCart)
          })
      } else {
        setUser(null)
        if (localStorage.getItem('cart')) {
          getDoc({ path: 'carts', docId: localStorage.getItem('cart') }).then(
            (result) => {
              if (result) {
                dbCartListener = db
                  .collection('carts')
                  .doc(localStorage.getItem('cart'))
                  .onSnapshot((snap) => {
                    const uCart = {
                      id: snap.id || null,
                      products: [],
                      ...snap.data(),
                    }
                    setCart(uCart)
                  })
              } else {
                addDoc({
                  path: 'carts',
                  data: { products: [], cartType: 'guest' },
                }).then((docId) => {
                  localStorage.setItem('cart', docId)
                  dbCartListener = db
                    .collection('carts')
                    .doc(docId)
                    .onSnapshot((snap) => {
                      const uCart = {
                        id: docId,
                        products: [],
                        ...snap.data(),
                      }
                      setCart(uCart)
                    })
                })
              }
            }
          )
        } else {
          addDoc({
            path: 'carts',
            data: { products: [], cartType: 'guest' },
          }).then((docId) => {
            localStorage.setItem('cart', docId)
            dbCartListener = db
              .collection('carts')
              .doc(docId)
              .onSnapshot((snap) => {
                const uCart = {
                  id: docId,
                  products: [],
                  ...snap.data(),
                }
                setCart(uCart)
              })
          })
        }
        dbUserListener()
      }
    })
    return () => {
      userStateListener()
      dbUserListener()
      setUser(null)
      setCart({ id: null, products: [] })
      dbCartListener()
    }
  }, [])

  useEffect(() => {
    promiseAllValues({
      cities: getCollection({ path: 'cities', docIdName: 'cityId' }),
      offices: getCollection({ path: 'offices', docIdName: 'officeId' }),
    }).then((result) => {
      setCities(
        defineDefaultCity({
          cities: result.cities.map((c) => ({
            ...c,
            offices: result.offices.filter((o) => o.cityId === c.cityId),
          })),
        })
      )
    })
    // if (localStorage.getItem('location')) {
    //   getDoc({
    //     path: 'cities',
    //     docId: localStorage.getItem('location'),
    //     docIdName: 'id',
    //   }).then((l) => setLocation(l))
    // }
  }, [])

  // useEffect(() => {
  //   const product = cart.products[0]
  //   if (
  //     (product && location.id && product.locationId !== location.id) ||
  //     (product && !location.id)
  //   ) {
  //     setShowLocationNotice(true)
  //   } else {
  //     setShowLocationNotice(false)
  //   }
  // }, [location, cart])

  return (
    <AuthContext.Provider
      value={{
        cities,
        setCities,
        showContactUs,
        setShowContactUs,
        user,
        showAuth,
        setShowAuth,
        // category,
        // setCategory,
        // location,
        // setLocation,
        // dates,
        // setDates,
        cart,
      }}
    >
      {/* {showLocationNotice && <LocationNotificationPopUp />} */}
      {children}
    </AuthContext.Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
