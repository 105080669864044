import { find, isEmpty, orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import getCollection from '../../utils/db/getCollection'
import SitePage from '../SitePage/SitePage'
import DoctorTile from './components/DoctorTile/DoctorTile'
import './Doctors.sass'

function Doctors() {
  const [doctors, setDoctors] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'doctors',
      docIdName: 'doctorId',
    }).then((data) =>
      setDoctors(
        orderBy(data, ['pos', 'asc']).filter((d) => d.isDeleted === false)
      )
    )
  }, [])

  return (
    <SitePage>
      <div className="DoctorsPage">
        <div className="Doctors-Container DefaultContainer">
          <SectionTitle title="Наши специалисты" />
          <div className="Doctors-Content">
            {!isEmpty(doctors) &&
              doctors.map((d) => {
                const avatar = find(d.photos, ['isAvatar', true])
                const stage = new Date().getFullYear() - d.startingYear
                return (
                  <DoctorTile
                    key={d.doctorId}
                    {...d}
                    stage={stage}
                    avatar={avatar ? avatar.publicUrl : null}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </SitePage>
  )
}

export default Doctors
