export default function handleSignUpErrors({ code, setNBlockSettings }) {
  switch (code) {
    case 'existByPhoneAndEmail':
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Этот телефон и e-mail уже зарегистрирован',
        content:
          'Кажется, у вас уже есть личный кабинет. Попробуйте войти или восстановите пароль.',
      })
    case 'existByPhone':
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Этот номер телефона уже зарегистрирован',
        content:
          'Кажется, у вас уже есть личный кабинет. Попробуйте войти или восстановите пароль.',
      })
    case 'existByEmail':
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Этот e-mail уже зарегистрирован',
        content:
          'Кажется, у вас уже есть личный кабинет. Попробуйте войти или восстановите пароль.',
      })

    default:
      return setNBlockSettings({
        show: true,
        theme: 'error',
        title: 'Unhandled error',
        content: `log for dev: ${code}`,
      })
  }
}
