import React, { useContext, useState } from 'react'
import PopUp from '../../../../../ui/PopUp/PopUp'
import Button from '../../../../../ui/Button/Button'
import './GetDocPopUp.sass'
import updateDoc from '../../../../../utils/db/updateDoc'
import getDoc from '../../../../../utils/db/getDoc'
import { AuthContext } from '../../../../..'

function GetDocPopUp({ close, eventId, eventType }) {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext(AuthContext)

  const onConfirm = () => {
    setIsLoading(true)
    if (eventType === 'individual') {
      updateDoc({
        path: 'schedule',
        docId: eventId,
        data: {
          status: 'awaitResult',
          updated: new Date(),
        },
      }).then(() => close())
    }
    // else {
    //   getDoc({ path: 'schedule', docId: eventId })
    //     .then((result) => {
    //       const members = result.members.filter((m) => m.userId !== user.uid)
    //       console.log(
    //         '🚀 ~ file: CancelEventPopUp.jsx:29 ~ .then ~ members:',
    //         members
    //       )

    //       return updateDoc({
    //         path: 'schedule',
    //         docId: eventId,
    //         data: {
    //           members,
    //         },
    //       })
    //     })
    //     .then(() => close())
    // }
  }

  return (
    <PopUp
      show
      title={'Запросить результаты Online'}
      className={`CancelEventPopUp`}
      close={close}
    >
      <p className="Text Body-Regular-2">
        Вы уверены, что хотите запросить результаты?
        {/* <span className="Body-Bold-2">
          •••• {number.slice(number.length - 4)}
        </span> */}
      </p>
      <div className="Buttons">
        <Button
          title="Запросить результат"
          size={40}
          onClick={onConfirm}
          isLoading={isLoading}
        />
        <Button
          title="Закрыть"
          size={40}
          theme="internal-link"
          onClick={close}
        />
      </div>
    </PopUp>
  )
}

export default GetDocPopUp
