import React, { useState, useContext, useRef, useEffect } from 'react'
import './CartPopUp.sass'
import PopUp from '../../../ui/PopUp/PopUp'
import CartList from '../CartList/CartList'
import Button from '../../../ui/Button/Button'
import { AuthContext } from '../../../index'
import { cloneDeep, isEmpty, uniq } from 'lodash'
import getCollection from '../../../utils/db/getCollection'
import defineTotalPrice from '../../../pages/Catalog/Totals/defineTotalPrice'
import emptyCart from './emptyCart.svg'
import Img from '../../../ui/Img/Img'
import updateDoc from '../../../utils/db/updateDoc'
import { withRouter } from 'react-router-dom'
import promiseAllValues from '../../../utils/promiseAllValues'
import CartOnPage from '../CartOnPage/CartOnPage'

function CartPopUp({ close, ...router }) {
  const { user, cart, setShowAuth } = useContext(AuthContext)
  const [productsData, setProductsData] = useState(null)
  // const prodListRef = useRef()
  // const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   if (location.id) {
  //     promiseAllValues({
  //       products: getCollection({ path: 'products', docIdName: 'productId' }),
  //       shops: getCollection({
  //         path: 'shops',
  //         docIdName: 'shopId',
  //       }),
  //     }).then((result) => {
  //       const spots = []
  //       result.shops.forEach((s) => {
  //         s.spots.forEach((spot) => {
  //           if (spot.spotCity === location.id) {
  //             spots.push(spot.spotId)
  //           }
  //         })
  //       })
  //       setProductsData({
  //         products: result.products,
  //         spots: uniq(spots),
  //       })
  //     })
  //   }
  // }, [location.id])

  // const onCheckout = () => {
  //   const result = prodListRef.current.onCheckout()
  //   setIsLoading(true)
  //   updateDoc({
  //     path: 'carts',
  //     docId: cart.id,
  //     data: { products: result.products },
  //   }).then(() => {
  //     setIsLoading(false)
  //     if (!user) {
  //       close()
  //       return setShowAuth('signUp')
  //     } else {
  //       if (result.status) router.history.push('/checkout/pickup-points')
  //     }
  //   })
  // }

  // const chooseEquipment = () => {
  //   if (location.id && category.id) {
  //     router.history.push('/catalog')
  //     setCategory(cloneDeep(category))
  //     close()
  //   } else {
  //     router.history.push('/')
  //     close()
  //   }
  // }

  return (
    <PopUp show close={close} title="Корзина" className="CartPopUp">
      {isEmpty(cart.products) ? (
        <div className="EmptyCart">
          <img src={emptyCart} />
          <h3 className="Title">Ваша корзина пустая (:</h3>
          <p className="Description Body-Regular-1">
            К счастью, это легко исправить
          </p>
          {/* <Button title="Перейти к услугам" onClick={chooseEquipment} /> */}
        </div>
      ) : (
        <CartOnPage />
      )}

      {/* {!isEmpty(cart.products) && !isEmpty(productsData) && (
        <>
          <div className="PriceBlock">
            <h3 className="TotalsText">Всього:</h3>
            <h3 className="Price">
              {defineTotalPrice({
                cart,
                dates,
                productsData: productsData.products,
                spotsByLocation: productsData.spots,
              })}{' '}
              ₴*
            </h3>
            <p className="TotalsCaption Caption-Regular">
              * Фінальна вартість залежить від обраного пункту прокату
            </p>
          </div>
          <div className="Buttons">
            <Button
              title="Перейти до оформлення"
              isLoading={isLoading}
              onClick={onCheckout}
            />
          </div>
        </>
      )} */}
    </PopUp>
  )
}

export default withRouter(CartPopUp)
