import React from 'react'
import './Status.sass'
import Icon from '../Icon/Icon'

function Status({ icon, title, bgColor, color, borderColor = null }) {
  return (
    <div
      className="Status Caption-Regular"
      style={{
        backgroundColor: bgColor,
        color: color,
        ...(borderColor ? { border: `1px ${borderColor} solid` } : {}),
      }}
    >
      <Icon name={icon} />
      <span>{title}</span>
    </div>
  )
}

export default Status
