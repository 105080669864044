import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './SearchInput.sass'

const SearchInput = React.forwardRef(
  (
    { title, placeholder, dropdownElements, value, onValueDrop, button = null },
    ref
  ) => {
    return (
      <div className={`SearchInput ${value ? 'SearchInput_with_value' : ''}`}>
        <div ref={ref} className="SearchInput-Data">
          <p className="Caption-Medium Title">{title}</p>
          <p className={`Caption-Regular Placeholder`}>
            {value ? value : placeholder}
          </p>
        </div>
        {dropdownElements && dropdownElements}
        {value && !button ? (
          <div className="SearchInput-DropValue" onClick={onValueDrop}>
            <Icon name="times" weight="light" />
          </div>
        ) : button ? (
          button
        ) : null}
      </div>
    )
  }
)

export function CustomDdlistElement({
  icon,
  title,
  weight = 'solid',
  isActive,
  onClick,
}) {
  return (
    <div
      className={[
        'ListElement',
        ...(isActive ? ['ListElement_theme_active'] : []),
      ].join(' ')}
      onClick={onClick}
    >
      <div className="Icon-Container">
        <Icon name={icon} weight={weight} />
      </div>
      <span className="Body-Regular-2">{title}</span>
      <span className="Radio"></span>
    </div>
  )
}

export default SearchInput
