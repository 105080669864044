import React from 'react'
import './DoctorAvatar.sass'
import Icon from '../Icon/Icon'

function DoctorAvatar({ src = null, title }) {
  return (
    <div className="DoctorAvatar">
      {src ? <img src={src} alt={title} /> : <Icon name="user-md" />}
    </div>
  )
}

export default DoctorAvatar
