import React from 'react'
import './ScheduleTiles.sass'
import { groupBy, isEmpty, sortBy } from 'lodash'
import Icon from '../../../../../ui/Icon/Icon'
import {
  formatDateAsDayMMYearHHMM,
  formatDateAsDayMonthYear,
  formatDurationDate,
} from '../../../../../utils/date/dateFormat'
import toggleSpoiler from '../../../../../utils/toggleSpoiler'

function ScheduleTiles({ eventTiles, choosedTime, onTimeClick }) {
  const dateGroups = groupBy(
    sortBy(eventTiles, [(e) => e.dateStart.seconds * 1000]),
    (d) => formatDateAsDayMonthYear(d.dateStart.seconds * 1000)
  )

  return (
    <div className="ScheduleTiles">
      {Object.entries(dateGroups).map(([d, v], i) => (
        <div
          className={`ScheduleGroup ${
            i === 0 ? 'Spoiler_status_expanded' : ''
          }`}
        >
          <p className="ScheduleGroup-Header" onClick={toggleSpoiler}>
            <Icon name="angle-down" />
            <span className="Schedule-Title">{d}</span>
            <span className="Schedule-Description">
              Доступных записей: <span className="Black">{v.length}</span>
            </span>
            <span className="Schedule-Description">
              {' '}
              Ближайшее свободное время:{' '}
              <span className="Black">
                {v[0].eventType === 'individual'
                  ? v[0].timeStart
                  : `${v[0].timeStart} - ${v[0].timeEnd}`}
              </span>
            </span>
          </p>
          <div className="ScheduleGroup-Body">
            {v.map((d) => (
              <DateTile
                isActive={
                  !isEmpty(choosedTime) && choosedTime.eventId === d.eventId
                }
                dateStart={d.dateStart.seconds * 1000}
                dateEnd={d.dateEnd.seconds * 1000}
                eventType={d.eventType}
                onClick={() => onTimeClick(d.eventId)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

function DateTile({ eventType, dateStart, dateEnd, isActive, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`DateTile ${isActive ? 'DateTile_status_active' : ''}`}
    >
      <div className="DateIcon-Container">
        <Icon name="check" />
      </div>
      {eventType === 'group'
        ? formatDurationDate(dateStart, dateEnd)
        : formatDateAsDayMMYearHHMM(dateStart)}
    </div>
  )
}

export default ScheduleTiles
