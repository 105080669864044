import React from 'react'
import SitePage from '../SitePage/SitePage'
import Stepper from '../../ui/Stepper/Stepper'
import { withRouter } from 'react-router-dom'
import './Checkout.sass'
import Clinic from './components/Clinic/Clinic'
import { useState } from 'react'
import { cloneDeep, find, findIndex, isEmpty } from 'lodash'
import Price from './components/Price/Price'
import Doctors from './components/Doctors/Doctors'
import OnePageCheck from './components/OnePageCheck/OnePageCheck'
import { useContext } from 'react'
import { AuthContext } from '../..'
import { useEffect } from 'react'
import CheckoutSpecialization from './components/CheckoutSpecialization/CheckoutSpecialization'
import SuccessCheckout from './components/SuccessCheckout/SuccessCheckout'

function Checkout({ ...router }) {
  const { cart } = useContext(AuthContext)

  const [steps, setSteps] = useState([
    {
      id: 'clinic',
      status: 'notActive',
      title: 'Клиника',
      subTitle: 'Выберите клинику',
    },
    {
      id: 'specialization',
      status: 'notActive',
      title: 'Специализация',
      subTitle: 'Выберите специализацию',
    },
    {
      id: 'services',
      status: 'notActive',
      title: 'Услуги',
      subTitle: 'Выберите услуги',
      path: '/checkout/payment-methods',
    },
    {
      id: 'doctor',
      status: 'notActive',
      title: 'Запись на прием',
      subTitle: 'Выберите врача и удобное время записи',
      path: '/checkout/payment-methods',
    },
    // {
    //   id: 'schedule',
    //   status: 'notActive',
    //   title: 'Расписание',
    //   subTitle: 'Просмотр расписания',
    //   path: '/checkout/payment-methods',
    // },
    // {
    //   id: 'confirmation',
    //   status: 'notActive',
    //   title: 'Запись на прием',
    //   subTitle: 'Запись на прием',
    //   path: '/checkout/payment-methods',
    // },
  ])

  useEffect(() => {
    if (cart.id) {
      const clone = cloneDeep(steps)
      const activeStepId = cart.curStep ? cart.curStep : 'clinic'

      const index = findIndex(clone, ['id', activeStepId])
      clone.forEach((c, i) => {
        c.status = i < index ? 'done' : i === index ? 'active' : 'notActive'
      })
      setSteps(clone)
    }
  }, [cart])

  const activeStep = find(steps, ['status', 'active'])

  return (
    <SitePage>
      <div className="Checkout">
        <div className="DefaultContainer">
          {!isEmpty(activeStep) && (
            <>
              <div className="Checkout-Header">
                <h2 className="Checkout-Title">{activeStep.subTitle}</h2>
                <Stepper steps={steps} width="450px" />
              </div>
              {activeStep.id === 'clinic' && <Clinic />}
              {activeStep.id === 'specialization' && <CheckoutSpecialization />}
              {activeStep.id === 'services' && <Price />}
              {activeStep.id === 'doctor' && <Doctors />}
            </>
          )}
        </div>
      </div>
    </SitePage>
  )
}

export default withRouter(Checkout)
