import React from 'react'
import './InfoLine.sass'
import Icon from '../Icon/Icon'

function InfoLine({ icon, title, content, className }) {
  return (
    <p className={['InfoLine', ...(className ? [className] : [])].join(' ')}>
      {icon && (
        <div className="IconContainer">
          <Icon name={icon} />
        </div>
      )}
      <p className="Body-Medium-1">{title}</p>
      {content}
    </p>
  )
}

export default InfoLine
