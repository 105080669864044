import React, { useState, useContext } from 'react'
import './PaymentMethods.sass'
import EmptyArea from '../../../ui/EmptyArea/EmptyArea'
import Button from '../../../ui/Button/Button'
import PaymentCardPopUp from './PaymentCardPopUp/PaymentCardPopUp'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../ui/NotificationBlock/NotificationBlock'
import { AuthContext } from '../../../index'
import Radio from '../../../ui/Field/Radio/Radio'
import { cloneDeep, isEmpty } from 'lodash'
import Icon from '../../../ui/Icon/Icon'
import mastercardIcon from './assets/mc.png'
import visaIcon from './assets/vc.png'
import updateDoc from '../../../utils/db/updateDoc'
import DeletePaymentCardPopUp from './DeletePaymentCardPopUp/DeletePaymentCardPopUp'
import { find } from 'lodash'

function PaymentMethods() {
  const [showCardPopUp, setShowCardPopUp] = useState(false)
  const [showDeleteCardPopUp, setDeleteShowCardPopUp] = useState()
  const [nBlockSettings, setNBlockSettings] = useNotificationBlock()
  const { user } = useContext(AuthContext)

  const onPaymentCardAddSuccess = () => {
    setShowCardPopUp(false)
    setNBlockSettings({
      show: true,
      theme: 'success',
      content: 'Нова карта успішно додана',
    })
    setTimeout(() => setNBlockSettings({ show: false }), 3000)
  }
  const onPaymentCardAddFailed = () => {
    setShowCardPopUp(false)
    setNBlockSettings({
      show: true,
      theme: 'error',
      content: 'Не додана',
    })
    setTimeout(() => setNBlockSettings({ show: false }), 3000)
  }

  const onDefaultClicked = (number) => {
    const cards = cloneDeep(user.cards).map((c) => {
      c.isDefault = c.number === number
      return c
    })
    updateDoc({
      path: 'users',
      docId: user.uid,
      data: { cards, updated: new Date() },
    }).then(() => {
      setNBlockSettings({
        show: true,
        theme: 'success',
        content: 'Карту змінено на основну (надо текст)',
      })
      setTimeout(() => setNBlockSettings({ show: false }), 3000)
    })
  }

  const onPaymentCardDelete = (number) => {
    let cards = cloneDeep(user.cards)
    const cardToDelete = find(cards, ['number', number])
    if (cardToDelete.isDefault) {
      cards[0].isDefault = true
    }
    cards = cards.filter((c) => c.number !== number)

    updateDoc({
      path: 'users',
      docId: user.uid,
      data: { cards, updated: new Date() },
    }).then(() => {
      setDeleteShowCardPopUp(false)
      setNBlockSettings({
        show: true,
        theme: 'info',
        content: 'Карта видалена',
      })
      setTimeout(() => setNBlockSettings({ show: false }), 3000)
    })
  }

  return (
    <div className="PaymentMethods">
      {showCardPopUp && (
        <PaymentCardPopUp
          onPaymentCardAddSuccess={onPaymentCardAddSuccess}
          onPaymentCardAddFailed={onPaymentCardAddFailed}
          close={() => setShowCardPopUp(false)}
        />
      )}
      {showDeleteCardPopUp && (
        <DeletePaymentCardPopUp
          number={showDeleteCardPopUp}
          onPaymentCardDelete={onPaymentCardDelete}
          close={() => setDeleteShowCardPopUp(null)}
        />
      )}
      <h2>Способи оплати</h2>
      {nBlockSettings.show && <NotificationBlock {...nBlockSettings} />}
      {isEmpty(user.cards) ? (
        <EmptyArea
          content={
            <span className="Caption-Regular">У вас ще немає доданих карт</span>
          }
        />
      ) : (
        <div className="User-Cards">
          {user.cards.map((c) => (
            <PaymentCard
              {...c}
              onDefaultClicked={onDefaultClicked}
              onDeleteCardClicked={() => setDeleteShowCardPopUp(c.number)}
            />
          ))}
        </div>
      )}

      <div className="Buttons">
        <Button
          title="Додати карту"
          size={40}
          icon="plus"
          iconPosition="right"
          onClick={() => setShowCardPopUp(true)}
        />
      </div>
    </div>
  )
}

function PaymentCard({
  title,
  number,
  isDefault,
  onDefaultClicked,
  onDeleteCardClicked,
}) {
  let asset = <Icon name="credit-card" />
  switch (title.toLowerCase()) {
    case 'mastercard':
      asset = <img src={mastercardIcon} alt="MasterCard" />
      break
    case 'visa':
      asset = <img src={visaIcon} alt="Visa" />
      break

    default:
      asset = <Icon name="credit-card" />
      break
  }

  return (
    <div className="PaymentCard">
      <span onClick={() => onDefaultClicked(number)}>
        <Radio value={isDefault ? 'on' : 'off'} />
      </span>
      <div className="Card-Data">
        <div className="CardImg">{asset}</div>
        <div className="Content">
          {isDefault && (
            <p className="Caption-Regular DefaultCard">Основна карта</p>
          )}
          <p className="Body-Medium-2 Title">{title}</p>
          <p className="CardNumber Body-Regular-2">
            •••• {number.slice(number.length - 4)}
          </p>
        </div>
        <Icon
          name="trash"
          className="DeleteIcon"
          onClick={onDeleteCardClicked}
        />
      </div>
    </div>
  )
}

export default PaymentMethods
