import React, { useContext, useState } from 'react'
import './EditProfile.sass'
import { createForm } from '../../../utils/forms/createForm'
import FormRender from '../../../utils/forms/render/FormRender'
import PasswordReset from './PasswordReset/PasswordResetField'
import Icon from '../../../ui/Icon/Icon'
import UserObject from '../../Auth/SignUp/functions/userObject'
import { AuthContext } from '../../../index'
import Button from '../../../ui/Button/Button'
import GenderOptions from './GenderOptions/GenderOptions'
import syncForm from '../../../utils/forms/changed/syncForm'
import getFormValues from '../../../utils/forms/getFormValues'
import isFormValid from '../../../utils/forms/validation/isFormValid'
import updateDoc from '../../../utils/db/updateDoc'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../ui/NotificationBlock/NotificationBlock'
import PasswordResetPopUp from './PasswordReset/PasswordResetPopUp'
import Media from 'react-media'

function EditProfile() {
  const { user } = useContext(AuthContext)

  const [form, setForm] = useState(
    createForm({
      formPattern: new UserObject({ disabledFields: ['email', 'phone'] }),
      formData: user,
    })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [nBlockSettings, setNBlockSettings] = useNotificationBlock()
  const [showPasswordResetPopUp, setShowPasswordResetPopUp] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    const values = getFormValues({ form })
    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (
      !isFormValid({
        form,
        omitFields: user.signUpType !== 'manual' ? ['password'] : [],
      })
    ) {
      return setShowErrors(true)
    } else {
      setShowErrors(false)
      setIsLoading(true)
      updateDoc({
        path: 'users',
        docId: user.uid,
        data: { ...values, updated: new Date() },
      })
        .then(() => {
          setIsLoading(false)
          setNBlockSettings({
            show: true,
            theme: 'success',
            title: 'Изменения сохранены',
            content: 'Вы успешно обновили свой профиль.',
          })
          setTimeout(() => setNBlockSettings({ show: false }), 3000)
        })
        .catch((e) => {
          console.log('🚀 ~ file: EditProfile.jsx ~ line 54 ~ onSubmit ~ e', e)
        })
    }
  }

  const onPasswordUpdated = () => {
    setShowPasswordResetPopUp(false)
    setNBlockSettings({
      show: true,
      theme: 'success',
      title: 'Установлен новый пароль',
      content: 'Вы успешно установили новый пароль.',
    })
    setTimeout(() => setNBlockSettings({ show: false }), 3000)
  }

  return (
    <section className="EditProfile">
      {showPasswordResetPopUp && (
        <PasswordResetPopUp
          close={() => setShowPasswordResetPopUp(false)}
          onPasswordUpdated={onPasswordUpdated}
        />
      )}
      <h2
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Мой профиль{' '}
        <Button
          title="Сохранить профиль"
          size={32}
          theme="primary2"
          style={{ minWidth: '166px' }}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      </h2>
      {nBlockSettings.show && <NotificationBlock {...nBlockSettings} />}
      <div className="EditProfile-MainForm">
        <FormRender
          sections={[{ fields: ['name', 'email', 'lastName', 'phone'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
          isFormSaved
        />
      </div>
      <PasswordReset onPasswordReset={() => setShowPasswordResetPopUp(true)} />
      {/* <div className="EditProfile-FastOrder">
        <p className="Title">
          <Icon name="rabbit-fast" />
          <span className="Body-Medium-2">Дані для швидкого замовлення</span>
        </p>
        <div className="Fields">
          <FormRender
            sections={[{ fields: ['height', 'weight', 'footSize'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
      </div> */}
      <div className="EditProfile-About">
        <h4>О себе</h4>
        <div className="Fields">
          <FormRender
            sections={[{ fields: ['city', 'bDate'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          <GenderOptions
            value={form.gender.values}
            onGenderChanged={(gender) => {
              syncForm({
                propIdentifier: 'gender',
                form,
                setForm,
                newForm: gender,
              })
            }}
          />
        </div>
      </div>
      <Media
        query="(max-width: 1366px)"
        render={() => (
          <Button
            title="Сохранить профиль"
            size={40}
            icon="save"
            onClick={onSubmit}
            isLoading={isLoading}
            className="Mobile-SaveProfile"
          />
        )}
      />
    </section>
  )
}

export default EditProfile
