import React, { useContext } from 'react'
import DoctorAvatar from '../../../../ui/DoctorAvatar/DoctorAvatar'
import './Doctors.sass'
import { useEffect } from 'react'
import { useState } from 'react'
import promiseAllValues from '../../../../utils/promiseAllValues'
import getCollection from '../../../../utils/db/getCollection'
import { AuthContext } from '../../../..'
import {
  cloneDeep,
  find,
  groupBy,
  intersection,
  isEmpty,
  orderBy,
  sortBy,
} from 'lodash'
import { trueRussianDecline } from '../../../Doctors/components/DoctorTile/DoctorTile'
import CheckoutButtons from '../CheckoutButtons/CheckoutButtons'
import updateDoc from '../../../../utils/db/updateDoc'
import Button from '../../../../ui/Button/Button'
import { isBefore } from 'date-fns'
import {
  formatDateAsDayMMYearHHMM,
  formatDateAsDayMontYearHHMM,
} from '../../../../utils/date/dateFormat'
import PopUp from '../../../../ui/PopUp/PopUp'
import Icon from '../../../../ui/Icon/Icon'
import { withRouter } from 'react-router-dom'
import getDoc from '../../../../utils/db/getDoc'
import setDoc from '../../../../utils/db/setDoc'
import sendCheckoutEmail from './sendCheckoutEmail'
import ScheduleTiles from './ScheduleTiles/ScheduleTiles'

function Doctors({ ...router }) {
  const { user, cart, setShowAuth } = useContext(AuthContext)
  const [servicesWithDoctors, setServicesWithDoctors] = useState(null)
  const [docsWithTime, setDocsWithTime] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onDocChoosed = (data) => {
    if (isEmpty(docsWithTime)) {
      setDocsWithTime([data])
    } else {
      let clone = cloneDeep(docsWithTime)
      clone = clone.filter((c) => c.specializationId !== data.specializationId)
      clone.push(data)
      setDocsWithTime(clone)
    }
  }

  const onDocCanceled = (eventId) => {
    setDocsWithTime(docsWithTime.filter((d) => d.eventId !== eventId))
  }

  useEffect(() => {
    if (!isEmpty(cart.specialization)) {
      promiseAllValues({
        categories: getCollection({
          path: 'settings/price/categories',
          docIdName: 'categoryId',
        }),
        services: getCollection({
          path: 'settings/price/services',
          docIdName: 'serviceId',
        }),
        doctors: getCollection({
          path: 'doctors',
          docIdName: 'doctorId',
        }),
      }).then((result) => {
        const specInCart = result.categories.filter((c) =>
          cart.specialization.includes(c.categoryId)
        )
        const productsInCart = result.services.filter((s) =>
          cart.products.includes(s.serviceId)
        )
        const doctorsByServices = result.doctors
          .filter((d) => d.isDeleted === false)
          .filter((d) => {
            if (
              !isEmpty(intersection(d.doctorCats, cart.specialization)) ||
              !isEmpty(intersection(d.doctorServices, cart.products))
            ) {
              return true
            }
            return false
          })

        const arr = []

        specInCart.forEach((s) => {
          const categoryServices = productsInCart.filter(
            (p) => p.categoryId === s.categoryId
          )
          arr.push({
            categoryData: s,
            categoryServices,
            categoryDoctors: doctorsByServices.filter((d) =>
              d.doctorCats.includes(s.categoryId)
            ),
            servicesByDoctors: productsInCart
              .filter((p) => p.categoryId === s.categoryId)
              .map((p) => ({
                product: p,
                doctors: doctorsByServices.filter((d) =>
                  d.doctorServices.includes(p.serviceId)
                ),
              })),
          })
        })

        setServicesWithDoctors(arr)
      })
    }
  }, [cart])

  const onPrevStep = () => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { curStep: 'services' },
    })
  }
  const onNextStep = () => {
    if (isEmpty(user)) {
      return setShowAuth('signIn')
    } else {
      setIsLoading(true)
      Promise.all(
        docsWithTime.map((d) =>
          getDoc({ path: 'schedule', docId: d.eventId, docIdName: 'eventId' })
        )
      ).then((result) => {
        const errorTiles = []

        result.forEach((r) => {
          if (r.eventType === 'group') {
            if (r.eventLimit === r.members.length) {
              errorTiles.push(r.eventId)
            }
          } else {
            if (!isEmpty(r.user)) {
              errorTiles.push(r.eventId)
            }
          }
        })

        if (!isEmpty(errorTiles)) {
          return console.log('error')
        } else {
          Promise.all(
            result.map((r) => {
              return updateDoc({
                path: 'schedule',
                docId: r.eventId,
                data: {
                  ...(r.eventType === 'group'
                    ? {
                        members: [
                          ...r.members,
                          {
                            userName: `${user.name} ${user.lastName}`,
                            phone: {
                              metadata: {
                                country: 'RU',
                                formatInternational: `${user.phone}`,
                                formatNational: `${user.phone}`,
                                number: `${user.phone}`,
                              },
                              value: `${user.phone}`,
                            },
                            status: 'booked',
                            userId: user.uid,
                          },
                        ],
                      }
                    : {
                        user: {
                          userName: `${user.name} ${user.lastName}`,
                          phone: {
                            metadata: {
                              country: 'RU',
                              formatInternational: `${user.phone}`,
                              formatNational: `${user.phone}`,
                              number: `${user.phone}`,
                            },
                            value: `${user.phone}`,
                          },
                          userId: user.uid,
                        },
                        status: 'booked',
                        payment: {
                          paymentType: 'cash',
                        },
                      }),
                },
              })
            }),
            result.map((r) =>
              sendCheckoutEmail({ eventId: r.eventId, email: user.email })
            )
          ).then(() =>
            setDoc({
              path: 'carts',
              docId: user.uid,
              data: { products: [] },
            }).then(() =>
              router.history.push('/zapis-na-priem/uspeshnaya-zapis')
            )
          )
        }
      })

      // router.history.push('/zapis-na-priem/uspeshnaya-zapis')
    }
  }

  return (
    <div className="Doctors">
      {!isEmpty(servicesWithDoctors) &&
        servicesWithDoctors.map((sd) => (
          <>
            <h3>{sd.categoryData.title}</h3>
            <ServiceTile
              docsWithTime={docsWithTime}
              clinicId={cart.clinicId}
              {...sd}
              onDocChoosed={onDocChoosed}
              onDocCanceled={onDocCanceled}
            />
          </>
        ))}
      <CheckoutButtons
        onPrevStep={onPrevStep}
        onNextStep={onNextStep}
        isLoading={isLoading}
        isActiveNextStep={!isEmpty(docsWithTime) && true}
      />
    </div>
  )
}

function ServiceTile({
  clinicId,
  categoryData,
  servicesByDoctors,
  categoryServices,
  categoryDoctors,
  onDocChoosed,
  onDocCanceled,
  docsWithTime,
}) {
  return (
    <div className="ServiceTile">
      <h4 className="Title">Услуги</h4>
      {isEmpty(categoryDoctors) ? (
        servicesByDoctors.map((sbd) => (
          <div className="ServiceWithDoctor">
            <div className="hzCont">
              <p>{sbd.product.title}</p>
              <div className="DocsContainer">
                {sbd.doctors.map((d) => (
                  <DoctorCheckoutTile
                    docsWithTime={docsWithTime}
                    doctorId={d.doctorId}
                    clinicId={clinicId}
                    title={d.title}
                    categoryTitle={categoryData.title}
                    categoryId={categoryData.categoryId}
                    stage={new Date().getFullYear() - d.startingYear}
                    onTimeChoosed={(data) =>
                      onDocChoosed({ ...data, clinicId, doctorId: d.doctorId })
                    }
                    onDocCanceled={onDocCanceled}
                  />
                ))}
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div>
            {isEmpty(categoryServices) ? (
              <div className="NotificationText">
                <Icon name="info-circle" />
                Консультация специалиста
              </div>
            ) : (
              categoryServices.map((cs) => (
                <>
                  <p>{cs.title}</p>
                </>
              ))
            )}
          </div>
          <div className="DocsContainer">
            {categoryDoctors.map((d) => (
              <DoctorCheckoutTile
                docsWithTime={docsWithTime}
                doctorId={d.doctorId}
                clinicId={clinicId}
                title={d.title}
                avatar={d.photos}
                categoryTitle={categoryData.title}
                categoryId={categoryData.categoryId}
                stage={new Date().getFullYear() - d.startingYear}
                onTimeChoosed={(data) =>
                  onDocChoosed({ ...data, clinicId, doctorId: d.doctorId })
                }
                onDocCanceled={onDocCanceled}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

function DoctorCheckoutTile({
  doctorId,
  clinicId,
  title,
  avatar,
  categoryTitle,
  categoryId,
  stage,
  onTimeChoosed,
  docsWithTime,
  onDocCanceled,
}) {
  const [eventTiles, setEventTiles] = useState(null)
  const [showPopUp, setShowPopUp] = useState(false)
  const [choosedTime, setChoosedTime] = useState(null)

  const thisDoctorWithTime = find(docsWithTime, {
    doctorId,
    clinicId,
    specializationId: categoryId,
  })

  useEffect(() => {
    getCollection({
      path: 'schedule',
      docIdName: 'eventId',
      whereQueries: [
        { fieldPath: 'doctorId', op: '==', value: doctorId },
        { fieldPath: 'cityId', op: '==', value: clinicId },
        { fieldPath: 'specialization', op: '==', value: categoryId },
        { fieldPath: 'status', op: '==', value: 'empty' },
      ],
    }).then((result) => {
      const now = new Date()
      const futuredDates = sortBy(
        result.filter((d) =>
          isBefore(now, new Date(d.dateStart.seconds * 1000))
        ),
        [(d) => d.dateStart.seconds * 1000]
      )
      setEventTiles(futuredDates)
    })
  }, [])

  const avatarSrc = find(avatar, ['isAvatar', true])

  const onTimeClick = (eventId) => {
    setChoosedTime({ eventId, specializationId: categoryId })
  }

  const onPopUpClose = () => {
    setChoosedTime(null)
    setShowPopUp(false)
  }

  return (
    <div
      className={`DoctorTile ${
        !isEmpty(thisDoctorWithTime) ? 'DoctorTile_status_active' : ''
      }`}
    >
      {showPopUp && (
        <PopUp
          className="SchedulePopUp"
          show
          close={onPopUpClose}
          title="Выбор даты и времени записи"
        >
          <div className="Schedule-Container">
            {eventTiles[0].eventType === 'group' && (
              <div className="NotificationText">
                <Icon name="info-circle" />
                <b>Обратите внимание!</b> Приём ведётся в диапазоне выбранного
                времени в порядке очереди.
              </div>
            )}
            <ScheduleTiles
              eventTiles={eventTiles}
              choosedTime={choosedTime}
              onTimeClick={onTimeClick}
            />
          </div>
          <div className="Buttons">
            <Button
              theme="primary"
              title="Сохранить"
              type="button"
              size={40}
              path="#calc"
              onClick={() => {
                onTimeChoosed(choosedTime)
                onPopUpClose()
              }}
              disabled={isEmpty(choosedTime)}
            />
            <Button
              theme="secondary"
              title="Закрыть"
              type="button"
              size={40}
              onClick={onPopUpClose}
            />
          </div>
        </PopUp>
      )}

      <DoctorAvatar
        src={avatarSrc ? avatarSrc.publicUrl : null}
        title={title}
      />
      <div className="DoctorData">
        <h4 className="Title">{title}</h4>
        <div className="Doctor-Params">
          <div className="Param">
            <p className="Body-Bold-2">Специальность</p>
            <p className="Body-Regular-2">{categoryTitle}</p>
          </div>
          <div className="Param">
            <p className="Body-Bold-2">Стаж</p>
            <p className="Body-Regular-2">
              {stage} {trueRussianDecline('лет', 'год', 'года', stage)}
            </p>
          </div>

          <div className="Param">
            <p className="Body-Bold-2">Ближайшая запись</p>
            <p className="Body-Regular-2">
              {!isEmpty(eventTiles)
                ? formatDateAsDayMMYearHHMM(
                    eventTiles[0].dateStart.seconds * 1000
                  )
                : '-'}
            </p>
          </div>
          {isEmpty(thisDoctorWithTime) ? (
            <Button
              icon={'pencil-alt'}
              iconWeight="solid"
              size={24}
              onClick={() => setShowPopUp(true)}
              className={'ChooseDateButton'}
              title="Выбрать дату и время"
              disabled={isEmpty(eventTiles)}
            />
          ) : (
            <div className="Param" style={{ justifySelf: 'end' }}>
              <p className="Body-Bold-2">Ваша запись</p>
              <p className="Body-Regular-2">
                {!isEmpty(eventTiles)
                  ? formatDateAsDayMMYearHHMM(
                      eventTiles[0].dateStart.seconds * 1000
                    )
                  : '-'}
                <Button
                  icon={'times'}
                  iconWeight="solid"
                  size={24}
                  onClick={() => onDocCanceled(thisDoctorWithTime.eventId)}
                  className={'CancelDateButton'}
                  title="Отменить"
                />
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Doctors)
