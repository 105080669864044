import React from 'react'
import { Item, Image, Box, A } from 'react-html-email'
import EmailSignature from '../EmailSignature'

function EmailBody({ children }) {
  return (
    <div
      style={{
        width: '100%',
        background: '#ffffff',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          border: '1px solid #E2E6EB',
          borderRadius: '8px',
          marginBottom: '64px',
        }}
      >
        <Box
          align="center"
          style={{
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Item align="center" style={{ paddingTop: '16px' }}>
            <A href="https://asdnord.ru" target="_blank" textDecoration="none">
              <Image
                src="https://firebasestorage.googleapis.com/v0/b/isee-asd.appspot.com/o/logo-asd.png?alt=media&token=9dfeeb0f-6a17-426d-816a-2f0e7bf8e475"
                width={146}
                height={48}
                alt="АСДМС"
                style={{ display: 'block' }}
              />
            </A>
          </Item>
          <Item
            style={{
              padding: '40px',
              backgroundColor: '#fff',
              borderRadius: '16px',
            }}
          >
            <Box
              style={{
                width: '100%',
                fontSize: '16px',
              }}
            >
              {children}
              <EmailSignature />
            </Box>
          </Item>
        </Box>
      </div>
    </div>
  )
}

export default EmailBody
