import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../../index'

function PrivateRoute({ component, path, exact, redirectTo }) {
  const { user } = useContext(AuthContext)
  let redirectLink = null
  switch (redirectTo) {
    case 'signIn':
      redirectLink = '/auth/sign-in'
      break
    default:
      redirectLink = redirectTo
  }

  return user ? (
    <Route path={path} exact={exact} component={component} />
  ) : redirectTo ? (
    <Route path={path}>
      <Redirect to={redirectLink} />
    </Route>
  ) : (
    ''
  )
}

export default PrivateRoute
