import React, { useState, useEffect, useContext } from 'react'
import './PasswordRecoveryPage.sass'
import Logo from '../../ui/Logo/Logo'
import Button from '../../ui/Button/Button'
import Spinner from '../../ui/Spinner/Spinner'
import { withRouter } from 'react-router'
import { getUrlQueryParams, setUrlQueryParams } from '../../utils/queries/index'
import getCollection from '../../utils/db/getCollection'
import { isEmpty } from 'lodash'
import { AuthContext } from '../..'

function PasswordRecoveryPage({ ...router }) {
  const { setShowAuth } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const { recoveryCode } = getUrlQueryParams({
      history: router.history,
      params: ['recoveryCode'],
    })
    getCollection({
      path: 'users',
      whereQueries: [
        { fieldPath: 'passwordRecoveryCode', op: '==', value: recoveryCode },
      ],
    }).then((result) => {
      if (isEmpty(result)) return setIsLoading(false)
      else
        return router.history.push(
          setUrlQueryParams({
            link: result[0].passwordRecoveryPage,
            params: [['passwordReset', recoveryCode]],
          })
        )
    })
  }, [])

  return (
    <div className="PasswordRecoveryPage">
      <div className="PasswordRecoveryPage-Container">
        {isLoading ? (
          <Spinner type="module" />
        ) : (
          <>
            <Logo navLink path="/" />
            <div className="Text">
              <h2>Ссылка для восстановления пароля недействительна</h2>
              <p className="Body-Regular-2">
                Срок действия ссылки для восстановления пароля истек или вы ее
                уже использовали.
              </p>
            </div>
            <div className="Buttons">
              <Button
                title="Войти"
                theme="primary"
                onClick={() => setShowAuth('signIn')}
                size={40}
              />
              <Button
                title="Восстановить пароль"
                theme="secondary"
                onClick={() => setShowAuth('passwordRecovery')}
                size={40}
              />
            </div>
          </>
        )}
      </div>
      <p className="SupportMessage Caption-Regular">
        Если у вас возникли вопросы, пожалуйста, напишите нам на
        <a href="mailto: asdnord@yandex.ru" target="blank">
          asdnord@yandex.ru
        </a>
      </p>
    </div>
  )
}

export default withRouter(PasswordRecoveryPage)
