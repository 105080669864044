import React from 'react'
import './Field.sass'
import isUndefined from 'lodash/isUndefined'
import TextareaAutosize from 'react-autosize-textarea'
import { isString, isEmpty, isArray, isNumber } from 'lodash'

import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import Phone from './Phone/Phone'
import * as FIELD_MASK from '../../utils/forms/fieldMasks'

import Icon from '../Icon/Icon'

import Select from './Select/Select'
import AddressPicker from '../AddressPicker/AddressPicker'
import DateRange from './DateRange/DateRange'
import Checkbox from './Checkbox/Checkbox'

const getMask = (pattern) => {
  switch (pattern) {
    case 'phone':
      return FIELD_MASK.PHONE
    case 'date':
      return FIELD_MASK.DATE
    case 'time':
      return FIELD_MASK.TIME
    default:
      return pattern
  }
}

const field = (props) => {
  const invalid = !props.valid

  // Classes for field
  const fieldClass = ['Field']
  props.class && fieldClass.push(props.class)

  let lockIcon = null
  if (props.isChangable === false) {
    lockIcon = 'lock-alt'
    fieldClass.push('Field-ReadOnly')
  }

  if (
    props.fieldType !== 'checkbox' &&
    props.fieldType !== 'inputFile' &&
    props.fieldType !== 'inputPhoto' &&
    props.fieldType !== 'phone'
  ) {
    if (
      (isString(props.value) && props.value.trim() !== '') ||
      (isArray(props.value) && !isEmpty(props.value)) ||
      isNumber(props.value)
    ) {
      fieldClass.push('Field_with_value')
    }
  }

  if (props.fieldType === 'inputFile' && props.value.fileName) {
    fieldClass.push('Field_with_value')
  }

  if (props.fieldType === 'phone' && props.value.value) {
    fieldClass.push('Field_with_value')
  }

  if ((invalid && props.touched) || (props.showErrors && props.error)) {
    fieldClass.push('Field_status_invalid')
  }

  const iconClasses = ['Field-Icon']
  if (props.config.iconClass) {
    iconClasses.push(props.config.iconClass)
  }

  if (props.config.iconPosition) {
    fieldClass.push(
      props.config.iconPosition === 'left'
        ? 'Field-Icon_position_left'
        : 'Field-Icon_position_right'
    )
  }

  let field = null

  switch (props.fieldType) {
    case 'input':
      let input = (
        <input
          type={props.config.type}
          value={
            props.config.type === 'file' ? props.config.inputLabel : props.value
          }
          onChange={props.changed}
          placeholder={props.config.placeholder}
          step={props.config.step}
          min={props.config.min}
          max={props.config.max}
          disabled={props.isChangable === false}
        />
      )

      if (props.config.mask && props.config.mask !== 'number') {
        const mask = getMask(props.config.mask)
        input = (
          <InputMask
            mask={mask}
            maskChar={null}
            onChange={props.changed}
            value={props.value}
            disabled={props.isChangable === false}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                type={props.config.type}
                placeholder={props.config.placeholder}
                disabled={props.isChangable === false}
              />
            )}
          </InputMask>
        )
      } else if (props.config.mask === 'number') {
        input = (
          <NumberFormat
            thousandSeparator={' '}
            onChange={props.changed}
            value={props.value}
            placeholder={props.config.placeholder}
            disabled={props.isChangable === false}
          />
        )
      }

      field = (
        <div className={fieldClass.join(' ')}>
          {props.config.label && (
            <label className="Body-Bold-2">{props.config.label}</label>
          )}

          <div className="Field-Container">
            {input}
            {props.config.icon || lockIcon ? (
              <span
                onClick={
                  props.config.useDropValueButton
                    ? props.dropValue
                    : props.config.iconFunc
                }
                className={iconClasses.join(' ')}
              >
                <Icon
                  name={props.config.icon || lockIcon}
                  size={props.config.iconSize && props.config.iconSize}
                />
              </span>
            ) : null}
          </div>
          {/* {props.config.marker ? (
            <span className="Field-Marker">
              <Icon name={props.config.marker} />
            </span>
          ) : null} */}
          {/* {props.showErrors && props.error ? (
            <div className="Overline-Regular Field-Error">{props.error}</div>
          ) : null} */}
        </div>
      )
      break
    case 'textarea':
    case 'textarea-autosize':
      fieldClass.push('Field-Textarea')

      field = (
        <div className={fieldClass.join(' ')}>
          {props.config.label && (
            <label className="Body-Bold-2">{props.config.label}</label>
          )}
          <div className="Field-Container">
            {props.fieldType === 'textarea' ? (
              <textarea
                onChange={props.changed}
                value={props.value}
                placeholder={props.config.placeholder}
                disabled={props.isChangable === false}
              />
            ) : (
              <TextareaAutosize
                value={props.value}
                onChange={props.changed}
                placeholder={props.config.placeholder}
                disabled={props.isChangable === false}
              />
            )}
            <span className="Field-Bar" />
            {/* {props.showErrors && props.error ? (
              <div className="Field-Error">{props.error}</div>
            ) : null} */}
          </div>
        </div>
      )
      break
    case 'checkbox':
      field = (
        <Checkbox
          label={props.config.label}
          value={props.value ? 'on' : 'off'}
          onClick={props.changed}
        />
      )
      break
    case 'select':
      fieldClass.push('Field-Select')
      field = (
        <Select
          fieldConfig={props.config}
          beforeContent={props.beforeContent}
          extraClass={fieldClass.join(' ')}
          getOptions={props.config.getOptions}
          isSearchable={props.config.isSearchable}
          multiOptions={props.config.multiOptions}
          isChangable={
            !isUndefined(props.isChangable) ? props.isChangable : true
          }
          setPropAsLabel={props.setPropAsLabel}
          ddbStyles={props.ddbStyles}
          changed={props.changed}
          value={props.value}
          errors={props.showErrors && props.error ? props.error : null}
          dropValue={props.dropValue}
        />
      )
      break
    case 'inputFile':
      field = (
        <div className={fieldClass.join(' ')}>
          <input
            type="text"
            value={
              props.value
                ? `${props.value.fileName}.${props.value.fileExt}`
                : ''
            }
            readOnly
          />
          {props.config.icon || lockIcon ? (
            <span className="Field-Icon">
              <Icon name={props.config.icon || lockIcon} />
            </span>
          ) : null}
          {props.config.marker ? (
            <span className="Field-Marker">
              <Icon name={props.config.marker} />
            </span>
          ) : null}
          <span className="Field-Bar" />
          <label>{props.config.label}</label>
          {/* {props.showErrors && props.error ? (
            <div className="Field-Error">{props.error}</div>
          ) : null} */}
          <input
            type="file"
            onChange={props.changed}
            disabled={props.isChangable === false}
          />
        </div>
      )
      break

    case 'phone':
      field = (
        <Phone
          fieldClass={fieldClass}
          defaultCountry="RU"
          {...props}
          disabled={props.isChangable === false}
        />
      )
      break
    case 'dateRange':
      fieldClass.push('Field-DateRange')
      field = <DateRange fieldClass={fieldClass} {...props} />
      break
    case 'address':
      field = (
        <AddressPicker
          formData={props.value}
          showErrors={props.required ? props.showErrors : null}
          syncState={(state) => props.changed({ target: { value: state } })}
          disabled={props.isChangable === false}
        />
      )
      break

    default:
      break
  }

  return field
}

export default field
