import React, { useContext, useEffect, useState } from 'react'
import PriceTable from '../../../../components/Price/PriceTable'
import getCollection from '../../../../utils/db/getCollection'
import { find, isEmpty, orderBy } from 'lodash'
import './Price.sass'
import { AuthContext } from '../../../..'
import promiseAllValues from '../../../../utils/promiseAllValues'
import { ServicesPrice } from '../../../Services/Services'
import CartOnPage from '../../../../components/Cart/CartOnPage/CartOnPage'
import CheckoutButtons from '../CheckoutButtons/CheckoutButtons'
import updateDoc from '../../../../utils/db/updateDoc'

function Price() {
  const { cart } = useContext(AuthContext)
  const [price, setPrice] = useState(null)

  useEffect(() => {
    if (!isEmpty(cart.specialization)) {
      promiseAllValues({
        categories: getCollection({
          path: 'settings/price/categories',
          docIdName: 'categoryId',
        }),
        services: getCollection({
          path: 'settings/price/services',
          docIdName: 'serviceId',
        }),
      }).then((result) => {
        const catsBySpecialization = result.categories.filter((c) =>
          cart.specialization.includes(c.categoryId)
        )
        const servicesBySpecialization = result.services.filter(
          (s) =>
            find(catsBySpecialization, ['categoryId', s.categoryId]) &&
            s.isDeleted !== true
        )
        // catsBySpecialization.map((category) => ({
        //   ...category,
        //   services: servicesBySpecialization.filter(
        //     (s) => s.categoryId === category.categoryId
        //   ),
        // }))

        setPrice(
          cart.specialization.map((cc) => {
            return {
              ...find(catsBySpecialization, ['categoryId', cc]),
              services: servicesBySpecialization.filter(
                (s) => s.categoryId === cc
              ),
            }
          })
        )
      })
    }
  }, [cart])

  const onPrevStep = () => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { curStep: 'specialization', products: [] },
    }).then(() => {})
  }

  const onNextStep = () => {
    updateDoc({
      path: 'carts',
      docId: cart.id,
      data: { curStep: 'doctor' },
    }).then(() => {})
  }

  return (
    <>
      <div className="PriceSection">
        <ServicesPrice
          price={price}
          openPriceId={
            cart && !isEmpty(cart.specialization)
              ? cart.specialization[0]
              : null
          }
          hideServicesOnCheckout
        />
        <CartOnPage hideCheckoutButton />
      </div>
      <CheckoutButtons
        onPrevStep={onPrevStep}
        onNextStep={onNextStep}
        isActiveNextStep={!isEmpty(cart.products) && true}
      />
    </>
  )
}

export default Price
