import React, { useContext } from 'react'
import './ProfilePopUp.sass'
import Button from '../../../../ui/Button/Button'
import { AuthContext } from '../../../../index'
import Avatar from '../../Avatar/Avatar'
import { profileLinks, ProfileNavItem } from '../../ProfileNav/ProfileNav'
import Icon from '../../../../ui/Icon/Icon'
import { slice } from 'lodash'
import { firebase } from '../../../../config/firebase'
import { Link, NavLink } from 'react-router-dom'

const ProfilePopUp = React.forwardRef(({ close }, ref = null) => {
  const { user, setShowAuth } = useContext(AuthContext)
  return (
    <div ref={ref} className="ProfilePopUp">
      {user ? (
        <UserProfilePopUp closeProfilePopUp={close} />
      ) : (
        <div className="UnsignedUser">
          <p className="Body-Regular-2">Войдите или зарегистрируйтесь</p>
          <div className="Buttons">
            <Button
              title="Войти"
              theme="primary"
              size={40}
              onClick={() => setShowAuth('signIn')}
            />
            <Button
              title="Зарегистрироваться"
              theme="secondary"
              size={40}
              onClick={() => setShowAuth('signUp')}
            />
          </div>
        </div>
      )}
    </div>
  )
})

function UserProfilePopUp({ closeProfilePopUp }) {
  const { user } = useContext(AuthContext)

  return (
    <div className="UserProfilePopUp">
      <Link to="/profile" className="Profile-Credentials">
        <Avatar asset={user.avatar} />
        <span className="UserName Body-Bold-2">{`${user.name} ${user.lastName}`}</span>
        <Icon name="angle-right" className="AngleRight" />
      </Link>
      <div className="Profile-Links">
        {slice(profileLinks, 1, profileLinks.length).map((l) => (
          <ProfileNavItem {...l} />
        ))}
      </div>
      <div className="Profile-SignOut">
        <ProfileNavItem
          icon="sign-out"
          title="Выход"
          onClick={() => {
            console.log('here')
            firebase.auth().signOut()
            closeProfilePopUp()
          }}
        />
      </div>
    </div>
  )
}

export default ProfilePopUp
