// import sendConfirmationSms from './sendConfirmationSms'
import updateDoc from '../../../../utils/db/updateDoc'
import sendEmailVerificationCode from '../../SignUp/functions/sendEmailVerificationCode'

const genNewConfirmationSms = ({ uid, phoneNumber, email }) => {
  const confirmationCode = (Math.floor(Math.random() * 10000) + 10000)
    .toString()
    .substring(1)

  return (
    updateDoc({
      path: 'users',
      docId: uid,
      data: { phoneVerificationCode: confirmationCode },
    })
      .then(() =>
        sendEmailVerificationCode({ to: email, code: confirmationCode })
      )
      // .then(() => sendConfirmationSms({ to: phoneNumber, confirmationCode }))
      .then((res) => res.data)
      .catch(() => ({ result: false }))
  )
}

export default genNewConfirmationSms
