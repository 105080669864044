import React from 'react'
import './SocialAuth.sass'
import Button from '../../../ui/Button/Button'
import { firebase } from '../../../config/firebase'
import signUpWithSocial from './functions/signUpWithSocial'

function SocialAuth({ title, close }) {
  const facebookProvider = new firebase.auth.FacebookAuthProvider()
  const googleProvider = new firebase.auth.GoogleAuthProvider()

  const onSubmit = async (provider) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        if (result.additionalUserInfo.isNewUser) {
          return signUpWithSocial(result)
        } else {
          close()
        }
      })
      .catch((e) => {
        console.log(
          '🚀 ~ file: SocialAuth.jsx ~ line 18 ~ firebase.auth ~ e',
          e
        )
      })
  }

  return (
    <div className="SocialAuth">
      <p className="SocialAuth-Title">
        <span className="Body-Regular-2">{title}</span>
      </p>
      <div className="SocialAuth-Buttons">
        <Button
          title="Google"
          icon="google"
          iconPosition="left"
          iconWeight="brand"
          size={40}
          theme="secondary"
          onClick={() => onSubmit(googleProvider)}
        />
      </div>
    </div>
  )
}

export default SocialAuth
