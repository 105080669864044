import React, { useEffect, createContext, useState, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Home from './pages/Home/Home'
// import { db, firebase } from './config/firebase'
// import CatalogIndex from './pages/Catalog/CatalogIndex'
import Checkout from './pages/Checkout/Checkout'
// import PaymentStatusPage from './pages/PaymentStatusPage/PaymentStatusPage'
import Profile from './components/Profile/Profile'
import Auth from './components/Auth/Auth'
import PrivateRoute from './hocs/routs/PrivateRoute'
// import PasswordResetEmail from './components/Emails/PasswordResetEmail'
import PasswordRecoveryPage from './pages/PasswordRecoveryPage/PasswordRecoveryPage'
// import getDoc from './utils/db/getDoc'
// import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions'
// import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
// import Faq from './pages/Faq/Faq'
// import Page404 from './pages/404/404'
// import PaymentProcess from './pages/PaymentProcess/PaymentProcess'
// import addDoc from './utils/db/addDoc'
// import setDoc from './utils/db/setDoc'
// import LocationNotificationPopUp from './ui/LocationNotificationPopUp/LocationNotificationPopUp'
// import { find, isEmpty, isNull } from 'lodash'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
// import Rent from './pages/Rent/Rent'
// import Vikup from './pages/Vikup/Vikup'
// import Buy from './pages/Buy/Buy'
// import ToRent from './pages/ToRent/ToRent'
// import { defineDefaultCity } from './components/CityChooser/CityChooser'
// import ContactUsPopUp from './components/ContactUs/ContactUsPopUp'
// import { useContext } from 'react'
import MedComissions from './pages/MedComissions/MedComissions'
import { AuthContext } from '.'
import { isNull } from 'lodash'
import Services from './pages/Services/Services'
import Doctors from './pages/Doctors/Doctors'
import NewsPage from './pages/NewsPage/NewsPage'
import CookieNotification from './components/CookieNotification/CookieNotification'
import SuccessCheckout from './pages/Checkout/components/SuccessCheckout/SuccessCheckout'
import CheckoutEmail from './components/Emails/CheckoutEmail'
import VerificationEmail from './components/Emails/VerificationEmail'
// import promiseAllValues from './utils/promiseAllValues'
// import getCollection from './utils/db/getCollection'

// const initDates = () => {
//   if (localStorage.getItem('date')) {
//     let dObject = JSON.parse(localStorage.getItem('date'))
//     dObject.start = new Date(dObject.start)
//     dObject.end = new Date(dObject.end)
//     return dObject
//   }
//   return { start: null, end: null }
// }

function App() {
  const { cities } = useContext(AuthContext)
  return !isNull(cities) ? (
    <>
      <ScrollToTop />
      <CookieNotification />
      <Auth />
      <Switch>
        <PrivateRoute path="/profile" component={Profile} redirectTo="/" />

        {/*  <Route
          path={['/catalog/:subCategoryId', '/catalog']}
          component={CatalogIndex}
        />
        <Route path="/checkout" component={Checkout} />
        <Route
          path="/payment/process"
          render={() => <PaymentProcess />}
        ></Route>
        <Route
          path="/payment/success"
          render={() => <PaymentStatusPage status="success" />}
        ></Route>
        <Route
          path="/payment/failed"
          render={() => <PaymentStatusPage status="failed" />}
        ></Route>

        <Route path="/email" component={PasswordResetEmail} />

        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/faq" component={Faq} />
        <Route path="/:notFound" component={Page404} /> */}
        <Route path="/password-recovery" component={PasswordRecoveryPage} />
        <Route path="/" exact component={Home} />
        <Route path="/vrachi" exact component={Doctors} />
        <Route path="/zapis-na-medkomissiyu" component={MedComissions} />
        <Route
          path={['/uslugi-i-ceny/:id', '/uslugi-i-ceny']}
          component={Services}
        />
        <Route path="/zapis-na-priem" exact component={Checkout} />
        <Route
          path="/zapis-na-priem/uspeshnaya-zapis"
          component={SuccessCheckout}
        />
        <Route path="/novosti/:id" component={NewsPage} />
        <Route path="/email" component={VerificationEmail} />
      </Switch>
    </>
  ) : null
}

export default App
